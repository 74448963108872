import React from 'react';
import { gui_module, sceneItems } from '../smartkits/gui_smartkits';
import { product_manager_module } from '../smartkits/product_manager_module';
import { shed_module } from '../smartkits/shed_module';
import { building_module } from '../smartkits/building_module';

const landscapingTypes = [
    { value: 'invisible', label: 'Invisible' },
    { value: 'decking', label: 'Decking' },
    { value: 'concrete', label: 'Concrete' },
    { value: 'tile', label: 'Tile' },
    { value: 'stone', label: 'Stone' },
  ];

class SmartLandscapingSetupubmenu extends React.Component {
  constructor() {
    super();
    this.state =  { 
      fan: false,
      downlightNumber: 0,
      landscapingType: landscapingTypes[0].value,
      lock: false,
      x: 0,
      z: 0
    }   
  }

  setFan = (event) => {
    var v = event.target.checked
    this.setState({
      fan: v
    }) 
    gui_module.fan_gui.setValue(v)
  }
  
  setDownlightNumber  = (event) => {
    var v = event.target.valueAsNumber
    this.setState( {
      downlightNumber: v
    });
    gui_module.downlight_gui.setValue(v)
  }

  setLandscapingType  = (event) => {
    var v = event.target.value
    this.setState( {
      landscapingType: v
    });
    if(v == 'invisible') {
        product_manager_module.set_landscaping_visible(gui_module.index - 1, false)
    } else {
        product_manager_module.set_landscaping_visible(gui_module.index - 1, true)
        product_manager_module.set_landscaping_type(gui_module.index - 1, v)
    }
  }

  resetPosition  = (event) => {
    sceneItems.controler.reset()
    sceneItems.scene.position.set(0, 0, -6)
  }

  setLock = (event) => {
    var v = event.target.checked
    this.setState({
      lock: v
    }) 
    var type = this.getProductType()
    if(type < 32) {
      gui_module.product_lock_gui.setValue(v)
    } else if(type == 32 || type == 33) {
      building_module.product_lock_gui.setValue(v)
    } else if(type > 63) {
      shed_module.product_lock_gui.setValue(v)
    } 
  }

  setLandscapingXOffset = (event) => {
    var v = event.target.valueAsNumber
    this.setState( {
      x: v
    });
    gui_module.landscaping_x_offset_gui.setValue(v)
  }

  setLandscapingZOffset = (event) => {
    var v = event.target.valueAsNumber
    this.setState( {
      z: v
    });
    gui_module.landscaping_z_offset_gui.setValue(v)
  }

  informSelect = () => {
    var product = product_manager_module.products[gui_module.index - 1]
    var type = 1
    type = product?.type
    var fan = true
    if(product?.fan > 0) fan = false
    this.setState({
        fan: fan,
        downlightNumber: product?.number,
        lock: product?.lock,
    })

    if (type < 32) {
      document.getElementById('landscaping_setup_sub').style.display = 'block'
    } else {
      document.getElementById('landscaping_setup_sub').style.display = 'none'
    }
  }

  getProductType = () => {
    var product = product_manager_module.products[gui_module.index - 1]
    if(product) return product.type
    else return 0
  }

  componentDidMount() {
    this.informSelect()
  }

  render() {
    return (
      <div>
       <div id='landscaping_setup'>
        <button  className="btn btn-secondary col-md9 mx-1 my-1">Lock</button>
        <input type="checkbox" checked={this.state.lock} onChange={this.setLock} />  
        <div id='landscaping_setup_sub'>
        <button  className="btn btn-secondary col-md9 mx-1 my-1">Fan</button>
        <input type="checkbox" checked={this.state.fan} onChange={this.setFan} />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Downlights</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='0'
            max='10'
            step='1'
            value={this.state.downlightNumber}
            onChange={this.setDownlightNumber}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='0'
            max='10'
            step='1'
            value={this.state.downlightNumber}
            onChange={this.setDownlightNumber}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Landscaping Type</button>
        <select  className="btn bg-info col-md-12 mx-1 my-1" 
            value={this.state.landscapingType} 
            onChange={this.setLandscapingType}
          >
          {landscapingTypes.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
        </select>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">X Offset</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='-0.2'
            max='3'
            step='0.1'
            value={this.state.x}
            onChange={this.setLandscapingXOffset}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='-0.2'
            max='3'
            step='0.1'
            value={this.state.x}
            onChange={this.setLandscapingXOffset}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Y Offset</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min='-0.2'
            max='3'
            step='0.1'
            value={this.state.z}
            onChange={this.setLandscapingZOffset}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min='-0.2'
            max='3'
            step='0.1'
            value={this.state.z}
            onChange={this.setLandscapingZOffset}
        />
        </div>
        <button className="btn btn-warning col-md-12 mx-1 my-1" onClick={this.resetPosition}>Reset Position</button>
        </div>
      </div>
     );  
  }
}

export default SmartLandscapingSetupubmenu;
