import React from 'react';
import { config } from '../smartkits/initSmartkits'
import { project_specification } from '../smartkits/gui_smartkits'

class SmartQuoteInformationSubmenu extends React.Component {
  constructor(props) {
      super(props);
      this.state =  { 
        show_load_project: true,
        submit_flag: !config.deployment, //false
        error: '',
        error_display: true,
        first_name: '',
        family_name: '',
        email: '',
        phone_no: '',
        build_street_no: '',
        build_street: '',
        build_suburb: '',
        build_post_code: '',
        build_state: states[0].value,
        build_address: '',
        address_loaded: false,
        creation_date: '',
        update_date: '',
        quote_type: '',
        comment: '',

  }   
  this.autocomplete = null;
  this.inputRef = React.createRef();
}

load = () => {
  document.getElementById('loadProductInput1').click()
}

startSmart = (event) => {
  var v = event.target.valueAsNumber
  this.props.callSelectMenuFromParent(4)
}

setFirstName(value) {
  this.setState({first_name: value})
  project_specification.first_name = value
  this.updateError();
}

setLastName(value) {
  this.setState({family_name: value})
  project_specification.family_name = value
  this.updateError();
}

setEmail(value) {
  this.setState({email: value})
  project_specification.email = value
  this.updateError();
}

setPhoneNo(value) {
  this.setState({phone_no: value})
  project_specification.phone_no = value
  this.updateError();
}

setStreetNo(value) {
  this.setState({build_street_no: value})
  project_specification.build_street_no = value
  this.updateError();
}

setStreet(value) {
  this.setState({build_street: value})
  project_specification.build_street = value
  this.updateError();
}

setSuburb(value) {
  this.setState({build_suburb: value})
  project_specification.build_suburb = value
  this.updateError();
}

setPostCode(value) {
  this.setState({build_post_code: value})
  project_specification.build_post_code = value
  this.updateError();
}

setBuildState(value) {
  this.setState({build_state: value})
  project_specification.build_state = value
  this.updateError();
}

validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email));
}

checkAllFields = () => {
  if(project_specification.first_name == '' 
    || project_specification.family_name == '' 
    || project_specification.build_street_no == '' 
    || project_specification.build_street == '' 
    || project_specification.build_suburb == ''
    || project_specification.build_post_code == ''
    || project_specification.build_state == ''
    ) {
    return({submit_flag: false, error: "Blank field", error_display: false}) 
  } else {       
    if(project_specification.email && !this.validateEmail(project_specification.email)) {
      return({submit_flag: false, error: "Wrong email format", error_display: false})  
    }  else {
      return({submit_flag: true, error: "", error_display: true}) 
    }
  }
}

updateError() {
  var r = this.checkAllFields()
  setTimeout(() => {
    this.setState({
      submit_flag: r.submit_flag,
      error: r.error,
      error_display: r.error_display
    })  
  }, 1000);
  
}

informSelect() {
}

projectLoaded = () => {
  this.setState({
    first_name: project_specification.first_name,
    family_name: project_specification.family_name,
    email: project_specification.email,
    phone_no: project_specification.phone_no,
    build_street_no:  project_specification.build_street_no,
    build_street:  project_specification.build_street,
    build_suburb:  project_specification.build_suburb,
    build_post_code:  project_specification.build_post_code,
    build_state:  project_specification.build_state,
    build_address:  
        project_specification.build_street_no + ' ' 
      + project_specification.build_street + ' '
      + project_specification.build_suburb + ' '
      + project_specification.build_state,
    address_loaded: true,
    show_load_project: false
  }) 

  setTimeout(() => {
    this.updateError()
  }, 500);

}

componentDidMount() {
  project_specification.call = this.projectLoaded;

  if (!window.google) {
    console.error("Google Maps JavaScript API library must be loaded.");
    return;
  }

  this.autocomplete = new window.google.maps.places.Autocomplete(this.inputRef.current, {
    fields: ['formatted_address', 'place_id', 'geometry.location', 'address_component'],
    types: ['address'],
    componentRestrictions: { country: 'au' }, // Optional: Restrict to a specific country
  });

  this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
}

 componentWillUnmount() {
  if (this.autocomplete) {
    window.google.maps.event.clearInstanceListeners(this.autocomplete);
  }
}

handlePlaceSelect = () => {
    const place = this.autocomplete.getPlace();
    
    if (place && place.address_components) {
      const address = place.formatted_address;
      const placeId = place.place_id;
      const location = place.geometry?.location;
      const street_number = place.address_components.find(component => component.types.includes('street_number'))?.long_name;
      const street = place.address_components.find(component => component.types.includes('route'))?.long_name;
      const city = place.address_components.find(component => component.types.includes('locality'))?.long_name;
      const state = place.address_components.find(component => component.types.includes('administrative_area_level_1'))?.short_name;
      const country = place.address_components.find(component => component.types.includes('country'))?.long_name;
      const postalCode = place.address_components.find(component => component.types.includes('postal_code'))?.long_name;

      this.setStreetNo(street_number)
      this.setStreet(street)
      this.setSuburb(city)
      this.setBuildState(state)
      this.setPostCode(postalCode)
      this.setState({address_loaded: false})

      // Do something with the selected data
      //console.log('Address:', address);
      //console.log('Street:', street);
      //console.log('City:', city);
      //console.log('State:', state);
      //console.log('Country:', country);
      //console.log('Postal Code:', postalCode);
      //console.log('Location:', location?.lat(), location?.lng());
      //console.log('Place ID:', placeId);
    }
  };

render() {
  return (
    <div>
     <div id='quote_information_setup'>
      {this.state.show_load_project && 
        (<div>
            <button className="btn btn-primary col-md-4 mx-1 mt-1 mb-4" onClick={this.load}>Load Project</button><br />
            <button className="btn btn-dark" title="Mandatory">Or Create a New Project: </button>
        </div>)
      }
      <div hidden={this.state.error_display}>
        <button className="btn btn-danger col-md-3 mt-1 mb-1" >{this.state.error}</button>
      </div>

      <table width='100%'>
        <tbody>
            <tr>
                <th width='5%'></th>
                <th width='25%'></th>
                <th width='50%'></th>                
            </tr>
            <tr>
                <td><button className="btn btn-danger" tabIndex="-1" title="Mandatory">*</button></td>
                <td>First name (Client):</td>
                <td><input type="text" className="col-md-6 mt-1 mb-1" placeholder="Enter name" 
                value={this.state.first_name}
                onChange={event => this.setFirstName(event.target.value)} /></td>
                
            </tr>
            <tr>
              <td><button className="btn btn-danger" tabIndex="-1" title="Mandatory">*</button></td>
                <td>Last name (Client):</td>
                <td><input type="text" className="col-md-6 mt-1 mb-1" placeholder="Enter last name" 
                value={this.state.family_name}
                onChange={event => this.setLastName(event.target.value)} /></td>
            </tr>
            <tr>
                <td><button className="btn btn-danger" tabIndex="-1" title="Mandatory">*</button></td>
                <td>Address (Client):</td>
                <td><input ref={this.inputRef} type="text" className="col-md-6 mt-1 mb-1" placeholder="Enter Address" /></td>
            </tr>
            {this.state.address_loaded && <tr>
                <td><button className="btn btn-danger" tabIndex="-1" title="Mandatory">*</button></td>
                <td>Entered Address (Client):</td>
                <td><input type="text" className="col-md-6 mt-1 mb-1" placeholder="Enter Address" value={this.state.build_address} /></td>
            </tr>}
            <tr>
                <td></td>
                <td>Phone No (Client):</td>
                <td><input type="text" className="col-md-6 mt-1 mb-1" placeholder="Enter phone number" 
                value={this.state.phone_no}
                onChange={event => this.setPhoneNo(event.target.value)} /></td>
            </tr>
            <tr>
                <td></td>
                <td>Email Address (Client):</td>
                <td><input type="text" className="col-md-6 mt-1 mb-1" placeholder="Enter email" 
                value={this.state.email}
                onChange={event => this.setEmail(event.target.value)} /></td>
            </tr>
          </tbody>
      </table>
      <button className="btn btn-warning col-md-4 mt-1 mb-1" disabled={!this.state.submit_flag} onClick={this.startSmart}>Start</button>
      </div>
    </div>
   );  
  }
}

const states = [
  { value: 'QLD', label: 'QLD' },
  { value: 'NSW', label: 'NSW' },
  { value: 'VIC', label: 'VIC' },
  { value: 'WA', label: 'WA' },
  { value: 'SA', label: 'SA' },
  { value: 'TAS', label: 'TAS' },
  { value: 'NT', label: 'NT' },
  { value: 'ACT', label: 'ACT' },

];

export default SmartQuoteInformationSubmenu;
