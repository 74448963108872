import { THREE, GUI, getObjectsKeys, updatePrice, selectGUISubmenu, setPriceDialog, colorSetType, hideElement, 
            act2TokenNotFound, setupFont, setupTextGeometry } from './helper.js';

import { initColorButton } from './color.js';
import { addAccessoriesGUI, setVisibleDecModels } from './model_modules.js'
import { product_manager_module } from './product_manager_module.js';
import { loader_module } from './loader_module.js';
import { building_module } from './building_module.js';
import { shed_module } from './shed_module.js';
import { image_import, forceSideBarVisible, setPanelVisible, setDialogVisible } from './image_import.js';
import { config } from './initSmartkits.js'

var project_specification = {
    first_name: '',
    family_name: '',
    email: '',
    phone_no: '',
    build_street_no: '',
    build_street: '',
    build_suburb: '',
    build_post_code: '',
    build_state: 'QLD',
    creation_date: '',
    update_date: '',
    quote_type: '',
    comment: '',
    call: () => { console.log('No Call Back')},
}

var sceneItems = {
    renderer: null,
    scene: null,       
    camera: null,
    light: null, 
    lignt_2nd: null,
    ambient: null,
    controler: null,
    grid: null, 
    sceneX: 0, 
    sceneY: 0,
}

var gui_module = {

    index: 1,
    index_buffer: 1,

    gui: null,

    roofingColorElement: null,
    gutterColorElement: null,
    beamColorElement: null,
    postColorElement: null,
    wallColorElement: null,
    rollerDoorColorElement: null,

    index_gui: null,
    length_gui: null,
    visible_gui: null,
    projection_gui: null,
    height_gui: null,
    step_length_gui: null,
    step_size_gui: null,
    bracket_size_gui: null,
    front_overhang_gui: null,
    back_overhang_gui: null,
    step_overhang_gui: null,
    front_post_offset_gui: [],
    back_post_offset_gui: [],
    right_post_offset_gui: [],
    left_post_offset_gui: [],
    bracket_offset_gui: [],
    bracket_length_gui: [],
    right_offset_gui: [],
    left_offset_gui: [],
    decking_post_offset_gui: [],
    rafter_offset_gui: [],
    purlin_offset_gui: [],
    front_post_offset_folder_gui: null,
    back_post_offset_folder_gui: null,
    right_post_offset_folder_gui: null,
    left_post_offset_folder_gui: null,
    decking_post_offset_folder_gui: null,
    bracket_offset_folder_gui: null,
    right_offset_folder_gui: null,
    left_offset_folder_gui: null,
    rafter_offset_folder_gui: null,
    purlin_offset_folder_gui: null,
    setup_gui: null,

    angle_gui: null,
    view_gui: null,
    profile_gui: null,

    visible: true,

    scale: 1,
    length: 3, //15.9,
    back_length: 1,
    projection: 3,
    height: 2.4,
    step_length: 0,
    step_size: 3,
    bracket_size: 1,
    
    //horizon: 0,
    altitude: 0,

    angle: 0,
    rotate: 90,
    profile: 0,
    front_overhang: 10,
    back_overhang: 10,
    step_overhang: 10,
    gable_type: 0,
    f_p_1_offset: -0.5,
    f_p_2_offset: +0.5,
    f_p_3_offset: 0,
    f_p_4_offset: 0,
    f_p_5_offset: 0,
    f_p_6_offset: 0,

    b_p_1_offset: -0.5,
    b_p_2_offset: +0.5,
    b_p_3_offset: 0,
    b_p_4_offset: 0,
    b_p_5_offset: 0,
    b_p_6_offset: 0,
    
    r_p_1_offset: -0.5,
    r_p_2_offset: +0.5,
    r_p_3_offset: 0,
    r_p_4_offset: 0,
    r_p_5_offset: 0,
    r_p_6_offset: 0,

    l_p_1_offset: -0.5,
    l_p_2_offset: +0.5,
    l_p_3_offset: 0,
    l_p_4_offset: 0,
    l_p_5_offset: 0,
    l_p_6_offset: 0,

    br_1_offset: -0.5,
    br_2_offset: +0.5,
    br_3_offset: 0,
    br_4_offset: 0,
    br_5_offset: 0,
    br_6_offset: 0,

    br_1_size: 0.2,
    br_2_size: 0.2,
    br_3_size: 0.2,
    br_4_size: 0.2,
    br_5_size: 0.2,
    br_6_size: 0.2,

    d_p_1_offset: -0.5,
    d_p_2_offset: +0.5,
    d_p_3_offset: 0,
    d_p_4_offset: 0,
    d_p_5_offset: 0,
    d_p_6_offset: 0,

    rf_1_offset: -0.5,
    rf_2_offset: +0.5,
    rf_3_offset: 0,
    rf_4_offset: 0,
    rf_5_offset: 0,
    rf_6_offset: 0,

    pr_1_offset: -0.5,
    pr_2_offset: +0.5,
    pr_3_offset: 0,
    pr_4_offset: 0,
    pr_5_offset: 0,
    pr_6_offset: 0,


    roofing: 15, 
    gutters: 16,
    beams: 17,
    posts: 5, 
    walls: 3,
    roller_doors: 4, 
    
    front_post_width: .08,
    forced_front_posts_no: 0,
    forced_brackets_no: 0,
    back_post_width: .08,
    forced_back_posts_no: 0,
    forced_right_posts_no: 0,
    forced_left_posts_no: 0,
    decking_post_width: .08,
    forced_decking_posts_no: 0,
    rafter_width: 0.05,
    purlin_width: 0.05,
    forced_rafters_no: 0,
    forced_purlins_no: 0,

    fan: false,
    number: 0,
    color: 0xffffff,
    downlight_gui: false,
    fan_gui: false,

    global_lock: false,

    landscaping_visible_gui: null,
    landscaping_type_gui: null,
    landscaping_x_offset_gui: null,
    landscaping_z_offset_gui: null,
    landscaping_height_gui: null,

    logo: null,

    lock: false,
    product_lock_gui: null,
    user_access: 'smartkits_auth',

    reset: function() {        
        sceneItems.controler.reset();
        product_manager_module.move(gui_module.index - 1, new THREE.Vector3(0, 0, 0))
    },
    create_product_gui: function(informSelect) {        
        gui_module.gui = new GUI();
        gui_module.set_color_gui()

        loader_module.get_logo(function(logo) {
            gui_module.logo = logo
            logo.position.set(0, 3, 0)
            sceneItems.scene.add(logo);
        })

        var checkToken = function () {
            try {
                var token = JSON.parse(window.sessionStorage.getItem('smartkits_token')).token       
                //console.log("Test validity: " + token)
                var payload = {
                    token: token
                }
                var xhr = new window.XMLHttpRequest()
                xhr.open('POST', '/validate/token', true)
                xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
                xhr.send(JSON.stringify(payload))
                xhr.onreadystatechange = function() { 
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        console.log("I AM VALID! Hurray")
                    } else if (xhr.readyState === 4) {
                        window.location.replace('/');
                    }
                }
                var ua = JSON.parse(window.sessionStorage.getItem('user_info')) 
                if(ua?.access) { gui_module.user_access = ua.access }
                initColorButton();
            } catch(e) {
                act2TokenNotFound()
            } 
        }
            
        setInterval(checkToken, 8 * 60 * 60 * 1000);
        checkToken();

        var guiFunctions = {            
            chooseColor: function () {                
                gui_module.gui.close();
                forceSideBarVisible(true);
                setPriceDialog(true)
               
            },
            reset: function() {       
                sceneItems.controler.reset();
            }
        }
        
        gui_module.setup_size_color_gui(informSelect)
        gui_module.gui.add(guiFunctions, "chooseColor").name("Choose Color");
        
     
        image_import.set_import_image_gui();
        gui_module.set_save_image_gui();
        gui_module.set_accessories_gui();
        gui_module.set_light_gui();
        gui_module.set_scene_gui();

        forceSideBarVisible(false); 
    },

    validate_step_length: function() {
        if(gui_module.step_length >= gui_module.length) {
            var size = Math.floor(gui_module.length);
            if(size == gui_module.length) {
                size -= 1;
            }
            this.step_length_gui.setValue(size)
        }
        var product = product_manager_module.products[gui_module.index - 1]
        if(product) {            
            if(product.type == 20 || product.type == 21) {
                if(gui_module.length > 20) gui_module.length_gui.setValue(20)
            }
            else if(product.type == 28 || product.type == 29) {
                if(gui_module.length > 16) gui_module.length_gui.setValue(16)
            }
        }
        forceSideBarVisible(false);
    },
    add: function() {          
        
        if(gui_module.product_type == "building_left") {
            product_manager_module.add(32)      
            gui_module.customize_gui(32) 
        } else if(gui_module.product_type == "building_right") {
            product_manager_module.add(33)      
            gui_module.customize_gui(32) 
        } else {
            if(gui_module.product_type == "insulated_flyover") {
                product_manager_module.add(1, 1)        
                gui_module.customize_gui(1)                   
            } else if(gui_module.product_type == "non_insulated_flyover") {
                product_manager_module.add(1, 2)       
                gui_module.customize_gui(2)                    
            } else if(gui_module.product_type == "insulated_patio_attached") {
                product_manager_module.add(2, 3)   
                gui_module.customize_gui(3)    
            } else if(gui_module.product_type == "non_insulated_patio_attached") {
                product_manager_module.add(2, 4)  
                gui_module.customize_gui(4)     
            } else if(gui_module.product_type == "insulated_patio_freestanding") {
                product_manager_module.add(3, 5) 
                gui_module.customize_gui(5)    
            } else if(gui_module.product_type == "non_insulated_patio_freestanding") {
                product_manager_module.add(3, 6)
                gui_module.customize_gui(6)     
            } else if(gui_module.product_type == "hip") {
                product_manager_module.add(4, 7) 
                gui_module.customize_gui(7)
            } else if(gui_module.product_type == "hip_attached") {
                product_manager_module.add(5, 8) 
                gui_module.customize_gui(8)     
            } else if(gui_module.product_type == "insulated_gable") {
                product_manager_module.add(7, 9)    
                gui_module.customize_gui(9)
            } else if(gui_module.product_type == "non_insulated_gable") {
                product_manager_module.add(7, 10)   
                gui_module.customize_gui(10) 
            } else if(gui_module.product_type == "insulated_gable_attached") {
                product_manager_module.add(8, 11)  
                gui_module.customize_gui(11)
            } else if(gui_module.product_type == "non_insulated_gable_attached") {
                product_manager_module.add(8, 12) 
                gui_module.customize_gui(12) 
            } else if(gui_module.product_type == "dutch") {
                product_manager_module.add(10, 13)     
                gui_module.customize_gui(13)
            } else if(gui_module.product_type == "dutch_attached") {
                product_manager_module.add(11, 14)   
                gui_module.customize_gui(14)
            } else if(gui_module.product_type == "gazebo_6_sided") {
                product_manager_module.add(20, 15)  
                gui_module.customize_gui(15) 
            } else if(gui_module.product_type == "gazebo_8_sided") {
                product_manager_module.add(21, 16)  
                gui_module.customize_gui(16) 
            } else if(gui_module.product_type == "ground_level_decking") {
                product_manager_module.add(28, 17)  
                gui_module.customize_gui(17) 
            } else if(gui_module.product_type == "high_set_decking") {
                product_manager_module.add(28, 18)  
                gui_module.customize_gui(18) 
            } else if(gui_module.product_type == "decking_landing") {
                product_manager_module.add(29, 29)  
                gui_module.customize_gui(29) 
            } else if(gui_module.product_type == "patio_carport") {
                product_manager_module.add(2, 19)  
                gui_module.customize_gui(19)     
            } else if(gui_module.product_type == "dutch_carport") {
                product_manager_module.add(10, 20) 
                gui_module.customize_gui(20)    
            } else if(gui_module.product_type == "gable_carport") {
                product_manager_module.add(7, 21)
                gui_module.customize_gui(21)    
            } else if(gui_module.product_type == "hip_carport") {
                product_manager_module.add(4, 22) 
                gui_module.customize_gui(22)
            } else if(gui_module.product_type == "caravan_boatport") {
                product_manager_module.add(2, 23) 
                gui_module.customize_gui(23)
            } else if(gui_module.product_type == "angled_attached") {
                product_manager_module.add(13, 24) 
                gui_module.customize_gui(24)
            } else if(gui_module.product_type == "angled_freestanding") {
                product_manager_module.add(14, 25) 
                gui_module.customize_gui(25)
            } else if(gui_module.product_type == "single_garage") {
                product_manager_module.add(64, 64) 
                gui_module.customize_gui(64)
            } else if(gui_module.product_type == "double_garage") {
                product_manager_module.add(65, 65) 
                gui_module.customize_gui(65)
            } else if(gui_module.product_type == "triple_garage") {
                product_manager_module.add(66, 66) 
                gui_module.customize_gui(66)
            } else if(gui_module.product_type == "gara_port") {
                product_manager_module.add(67, 67) 
                gui_module.customize_gui(67)
            } else if(gui_module.product_type == "skillion_garage") {
                product_manager_module.add(75, 75) 
                gui_module.customize_gui(75)
            } else if(gui_module.product_type == "hip_garage") {
                product_manager_module.add(80, 80) 
                gui_module.customize_gui(80)
            } else if(gui_module.product_type == "dutch_garage") {
                product_manager_module.add(85, 85) 
                gui_module.customize_gui(85)
            } else if(gui_module.product_type == "building") {
                product_manager_module.add(100, 100) 
                gui_module.customize_gui(100)
            }                 
        }
          
        gui_module.landscaping_visible_gui.setValue(false)
        gui_module.fan_gui.setValue(false);
        updatePrice()
    },  
    saveProduct: {
        getProductsInfo: function() {
            var symmetric, landscaping_visible, fan, obj

            var array = []
            var config = {
                "type": 1025,
                "first_name": project_specification.first_name,
                "family_name": project_specification.family_name,
                "email": project_specification.email,
                "phone_no": project_specification.phone_no,
                "build_street_no": project_specification.build_street_no,
                "build_street": project_specification.build_street,
                "build_suburb": project_specification.build_suburb,
                "build_post_code": project_specification.build_post_code,
                "build_state": project_specification.build_state,
                "create_date": project_specification.creation_date,
                "update_date": project_specification.update_date,
                "quot_type": project_specification.quote_type,
                "comment": project_specification.comment,
            } 
            array.push(config)
            var products = product_manager_module.products
            for(var i = 0; i < products.length; ++i) {                    

                if(products[i].type < 32 || products[i].type > 63) {
                    if(products[i].symmetric) symmetric = 1
                    else symmetric = 0
                    if(products[i].landscaping_visible) landscaping_visible = 1
                    else landscaping_visible = 0
                    if(products[i].fans_model && products[i].fans_model.length > 0 && products[i].fans_model[0].visible) fan = 1
                    else fan = 0  
                    

                    obj = {
                        "type": products[i].type.toFixed(0),
                        "price_index": products[i].price_index?.toFixed(0),
                        "length": products[i].length?.toFixed(2),
                        "back_length": products[i].back_length?.toFixed(2),
                        "projection": products[i].projection?.toFixed(2),
                        "height": products[i].height?.toFixed(2),
                        "step_length": products[i].step_length?.toFixed(2),
                        "step_size": products[i].step_size?.toFixed(2),                        
                        "bracket_size": products[i].bracket_size?.toFixed(2),
                        "angle": products[i].angle?.toFixed(2),
                        "rotate": products[i].rotate?.toFixed(2),
                        "horizon": products[i].horizon?.toFixed(2),
                        "front_overhang": products[i].front_overhang?.toFixed(2),
                        "back_overhang": products[i].back_overhang?.toFixed(2),
                        "step_overhang": products[i].step_overhang?.toFixed(2),
                        "roofing_type": products[i].roofing_type?.toFixed(2),
                        "gable_type": products[i].gable_type?.toFixed(2), 

                        "symmetric": symmetric,
                        "profile": products[i].profile?.toFixed(2),
                        "fan": fan,
                        "number": products[i].number?.toFixed(2),
                        "landscaping_visible": landscaping_visible,
                        "landscaping_type": products[i].landscaping_type,
                        "landscaping_x_offset": products[i].landscaping_x_offset?.toFixed(2),
                        "landscaping_z_offset": products[i].landscaping_z_offset?.toFixed(2),
                        
                        "x": products[i].position?.x.toFixed(2),
                        "y": products[i].position?.y.toFixed(2),
                        "z": products[i].position?.z.toFixed(2),

                        "front_post_width": products[i].front_post_width?.toFixed(2),
                        "forced_front_posts_no": products[i].forced_front_posts_no?.toFixed(2),
                        "decking_post_width": products[i].decking_post_width?.toFixed(2),
                        "forced_decking_posts_no": products[i].forced_decking_posts_no?.toFixed(2),
                        "forced_brackets_no": products[i].forced_brackets_no?.toFixed(2),
                        "forced_back_posts_no": products[i].forced_back_posts_no?.toFixed(2),
                        "forced_right_posts_no": products[i].forced_right_posts_no?.toFixed(2),
                        "forced_left_posts_no": products[i].forced_left_posts_no?.toFixed(2),
                        "forced_rafters_no": products[i].forced_rafters_no?.toFixed(2),
                        "forced_purlins_no": products[i].forced_purlins_no?.toFixed(2),
                        

                        "roofing": products[i].roofing?.toFixed(0),
                        "gutters": products[i].gutters?.toFixed(0),
                        "beams": products[i].beams?.toFixed(0),
                        "posts": products[i].posts?.toFixed(0),
                        "walls": products[i].walls?.toFixed(0),
                        "roller_doors": products[i].rollerDoors?.toFixed(0),

                        "model_type": products[i].model_type,
                        "wind_category":  products[i].wind_category,
                        "roof_sheet_type": products[i].roof_sheet_type,
                        "attachment_type": products[i].attachment_type,
                        "attachment_sub_type": products[i].attachment_sub_type,
                        "partial_attachment_type": products[i].partial_attachment_type,
                        "attachment_top": products[i].attachment_top?.toFixed(0),
                        "attachment_top_type": products[i].attachment_top_type,
                        "attachment_top_fascia_type": products[i].attachment_top_fascia_type,
                        "attachment_top_variation": products[i].attachment_top_variation,
                        "attachment_right": products[i].attachment_right?.toFixed(0),
                        "attachment_right_type": products[i].attachment_right_type,
                        "attachment_right_fascia_type": products[i].attachment_right_fascia_type,
                        "attachment_right_variation": products[i].attachment_right_variation,
                        "attachment_left": products[i].attachment_left?.toFixed(0),
                        "attachment_left_type": products[i].attachment_left_type,
                        "attachment_left_fascia_type": products[i].attachment_left_fascia_type,
                        "attachment_left_variation": products[i].attachment_left_variation,
                        "attachment_bottom": products[i].attachment_bottom?.toFixed(0),
                        "attachment_bottom_type": products[i].attachment_bottom_type,
                        "attachment_bottom_fascia_type": products[i].attachment_bottom_fascia_type,
                        "attachment_bottom_variation": products[i].attachment_bottom_variation,
                        "fascia_type": products[i].fascia_type,
                        "post_type": products[i].post_type,
                        "gutter_type": products[i].gutter_type,
                        "gutter_front": products[i].gutter_front?.toFixed(0),
                        "gutter_back": products[i].gutter_back?.toFixed(0),
                        "gutter_left": products[i].gutter_left?.toFixed(0),
                        "gutter_right": products[i].gutter_right?.toFixed(0),
                        "footing_type": products[i].footing_type,
                        "bracket_type": products[i].bracket_type,
                        "fascia_beam_type": products[i].fascia_beam_type,
                        "purlin_beam_type": products[i].purlin_beam_type,
                        "rafter_beam_type": products[i].rafter_beam_type,
                        "downpipe_type": products[i].downpipe_type,
                        "wind_options_importance_level": products[i].wind_options_importance_level?.toFixed(0),
                        "wind_options_blocking": products[i].wind_options_blocking?.toFixed(0),
                        "allow_RSBLC": products[i].allow_RSBLC?.toFixed(0),

                        "front_posts_offset_1": products[i].front_posts_offset[0]?.toFixed(2),
                        "front_posts_offset_2": products[i].front_posts_offset[1]?.toFixed(2),
                        "front_posts_offset_3": products[i].front_posts_offset[2]?.toFixed(2),
                        "front_posts_offset_4": products[i].front_posts_offset[3]?.toFixed(2),
                        "front_posts_offset_5": products[i].front_posts_offset[4]?.toFixed(2),
                        "front_posts_offset_6": products[i].front_posts_offset[5]?.toFixed(2),


                        "decking_posts_offset_1": products[i].decking_posts_offset[0]?.toFixed(2),
                        "decking_posts_offset_2": products[i].decking_posts_offset[1]?.toFixed(2),
                        "decking_posts_offset_3": products[i].decking_posts_offset[2]?.toFixed(2),
                        "decking_posts_offset_4": products[i].decking_posts_offset[3]?.toFixed(2),
                        "decking_posts_offset_5": products[i].decking_posts_offset[4]?.toFixed(2),
                        "decking_posts_offset_6": products[i].decking_posts_offset[5]?.toFixed(2),
                        
                        "back_posts_offset_1": products[i].back_posts_offset[0]?.toFixed(2),
                        "back_posts_offset_2": products[i].back_posts_offset[1]?.toFixed(2),
                        "back_posts_offset_3": products[i].back_posts_offset[2]?.toFixed(2),
                        "back_posts_offset_4": products[i].back_posts_offset[3]?.toFixed(2),
                        "back_posts_offset_5": products[i].back_posts_offset[4]?.toFixed(2),
                        "back_posts_offset_6": products[i].back_posts_offset[5]?.toFixed(2),

                        "right_posts_offset_1": products[i].right_posts_offset[0]?.toFixed(2),
                        "right_posts_offset_2": products[i].right_posts_offset[1]?.toFixed(2),
                        "right_posts_offset_3": products[i].right_posts_offset[2]?.toFixed(2),
                        "right_posts_offset_4": products[i].right_posts_offset[3]?.toFixed(2),
                        "right_posts_offset_5": products[i].right_posts_offset[4]?.toFixed(2),
                        "right_posts_offset_6": products[i].right_posts_offset[5]?.toFixed(2),

                        "left_posts_offset_1": products[i].left_posts_offset[0]?.toFixed(2),
                        "left_posts_offset_2": products[i].left_posts_offset[1]?.toFixed(2),
                        "left_posts_offset_3": products[i].left_posts_offset[2]?.toFixed(2),
                        "left_posts_offset_4": products[i].left_posts_offset[3]?.toFixed(2),
                        "left_posts_offset_5": products[i].left_posts_offset[4]?.toFixed(2),
                        "left_posts_offset_6": products[i].left_posts_offset[5]?.toFixed(2),                                                        

                        "bracket_offset_1": products[i].bracket_offset[0]?.toFixed(2),
                        "bracket_offset_2": products[i].bracket_offset[1]?.toFixed(2),
                        "bracket_offset_3": products[i].bracket_offset[2]?.toFixed(2),
                        "bracket_offset_4": products[i].bracket_offset[3]?.toFixed(2),
                        "bracket_offset_5": products[i].bracket_offset[4]?.toFixed(2),
                        "bracket_offset_6": products[i].bracket_offset[5]?.toFixed(2),

                        "bracket_length_1": products[i].bracket_length[0]?.toFixed(2),
                        "bracket_length_2": products[i].bracket_length[1]?.toFixed(2),
                        "bracket_length_3": products[i].bracket_length[2]?.toFixed(2),
                        "bracket_length_4": products[i].bracket_length[3]?.toFixed(2),
                        "bracket_length_5": products[i].bracket_length[4]?.toFixed(2),
                        "bracket_length_6": products[i].bracket_length[5]?.toFixed(2),

                        "rafter_offset_1": products[i].rafter_offset[0]?.toFixed(2),
                        "rafter_offset_2": products[i].rafter_offset[1]?.toFixed(2),
                        "rafter_offset_3": products[i].rafter_offset[2]?.toFixed(2),
                        "rafter_offset_4": products[i].rafter_offset[3]?.toFixed(2),
                        "rafter_offset_5": products[i].rafter_offset[4]?.toFixed(2),
                        "rafter_offset_6": products[i].rafter_offset[5]?.toFixed(2),

                        "purlin_offset_1": products[i].purlin_offset[0]?.toFixed(2),
                        "purlin_offset_2": products[i].purlin_offset[1]?.toFixed(2),
                        "purlin_offset_3": products[i].purlin_offset[2]?.toFixed(2),
                        "purlin_offset_4": products[i].purlin_offset[3]?.toFixed(2),
                        "purlin_offset_5": products[i].purlin_offset[4]?.toFixed(2),
                        "purlin_offset_6": products[i].purlin_offset[5]?.toFixed(2),

                        "acc_1_type":    products[i].accessories_model[0]?.type?.toFixed(0),
                        "acc_1_length":  products[i].accessories_model[0]?.length?.toFixed(2),                            
                        "acc_1_height":  products[i].accessories_model[0]?.height?.toFixed(2),
                        "acc_1_location": products[i].accessories_model[0]?.location?.toFixed(2),
                        "acc_1_horizon": products[i].accessories_model[0]?.horizon?.toFixed(2),

                        "acc_2_type":    products[i].accessories_model[1]?.type?.toFixed(0),
                        "acc_2_length":  products[i].accessories_model[1]?.length?.toFixed(2),
                        "acc_2_height":  products[i].accessories_model[1]?.height?.toFixed(2),
                        "acc_2_location": products[i].accessories_model[1]?.location?.toFixed(2),
                        "acc_2_horizon": products[i].accessories_model[1]?.horizon?.toFixed(2),

                        "acc_3_type":    products[i].accessories_model[2]?.type?.toFixed(0),
                        "acc_3_length":  products[i].accessories_model[2]?.length?.toFixed(2),
                        "acc_3_height":  products[i].accessories_model[2]?.height?.toFixed(2),
                        "acc_3_location": products[i].accessories_model[2]?.location?.toFixed(2),
                        "acc_3_horizon": products[i].accessories_model[2]?.horizon?.toFixed(2),

                        "acc_4_type":    products[i].accessories_model[3]?.type?.toFixed(0),
                        "acc_4_length":  products[i].accessories_model[3]?.length?.toFixed(2),
                        "acc_4_height":  products[i].accessories_model[3]?.height?.toFixed(2),
                        "acc_4_location": products[i].accessories_model[3]?.location?.toFixed(2),
                        "acc_4_horizon": products[i].accessories_model[3]?.horizon?.toFixed(2),

                        "acc_5_type":    products[i].accessories_model[4]?.type?.toFixed(0),
                        "acc_5_length":  products[i].accessories_model[4]?.length?.toFixed(2),
                        "acc_5_height":  products[i].accessories_model[4]?.height?.toFixed(2),
                        "acc_5_location": products[i].accessories_model[4]?.location?.toFixed(2),
                        "acc_5_horizon": products[i].accessories_model[4]?.horizon?.toFixed(2),

                        "acc_6_type":    products[i].accessories_model[5]?.type?.toFixed(0),
                        "acc_6_length":  products[i].accessories_model[5]?.length?.toFixed(2),
                        "acc_6_height":  products[i].accessories_model[5]?.height?.toFixed(2),
                        "acc_6_location": products[i].accessories_model[5]?.location?.toFixed(2),
                        "acc_6_horizon": products[i].accessories_model[5]?.horizon?.toFixed(2),

                        "acc_7_type":    products[i].accessories_model[6]?.type?.toFixed(0),
                        "acc_7_length":  products[i].accessories_model[6]?.length?.toFixed(2),
                        "acc_7_height":  products[i].accessories_model[6]?.height?.toFixed(2),
                        "acc_7_location": products[i].accessories_model[6]?.location?.toFixed(2),
                        "acc_7_horizon": products[i].accessories_model[6]?.horizon?.toFixed(2),

                        "acc_8_type":    products[i].accessories_model[7]?.type?.toFixed(0),
                        "acc_8_length":  products[i].accessories_model[7]?.length?.toFixed(2),
                        "acc_8_height":  products[i].accessories_model[7]?.height?.toFixed(2),
                        "acc_8_location": products[i].accessories_model[7]?.location?.toFixed(2),
                        "acc_8_horizon": products[i].accessories_model[7]?.horizon?.toFixed(2),

                        "acc_9_type":    products[i].accessories_model[8]?.type?.toFixed(0),
                        "acc_9_length":  products[i].accessories_model[8]?.length?.toFixed(2),
                        "acc_9_height":  products[i].accessories_model[8]?.height?.toFixed(2),
                        "acc_9_location": products[i].accessories_model[8]?.location?.toFixed(2),
                        "acc_9_horizon": products[i].accessories_model[8]?.horizon?.toFixed(2),

                        "acc_10_type":    products[i].accessories_model[9]?.type?.toFixed(0),
                        "acc_10_length":  products[i].accessories_model[9]?.length?.toFixed(2),
                        "acc_10_height":  products[i].accessories_model[9]?.height?.toFixed(2),
                        "acc_10_location": products[i].accessories_model[9]?.location?.toFixed(2),
                        "acc_10_horizon": products[i].accessories_model[9]?.horizon?.toFixed(2),

                        "acc_11_type":    products[i].accessories_model[10]?.type?.toFixed(0),
                        "acc_11_length":  products[i].accessories_model[10]?.length?.toFixed(2),
                        "acc_11_height":  products[i].accessories_model[10]?.height?.toFixed(2),
                        "acc_11_location": products[i].accessories_model[10]?.location?.toFixed(2),
                        "acc_11_horizon": products[i].accessories_model[10]?.horizon?.toFixed(2),

                        "acc_12_type":    products[i].accessories_model[11]?.type?.toFixed(0),
                        "acc_12_length":  products[i].accessories_model[11]?.length?.toFixed(2),
                        "acc_12_height":  products[i].accessories_model[11]?.height?.toFixed(2),
                        "acc_12_location": products[i].accessories_model[11]?.location?.toFixed(2),
                        "acc_12_horizon": products[i].accessories_model[11]?.horizon?.toFixed(2),

                        "acc_13_type":    products[i].accessories_model[12]?.type?.toFixed(0),
                        "acc_13_length":  products[i].accessories_model[12]?.length?.toFixed(2),
                        "acc_13_height":  products[i].accessories_model[12]?.height?.toFixed(2),
                        "acc_13_location": products[i].accessories_model[12]?.location?.toFixed(2),
                        "acc_13_horizon": products[i].accessories_model[12]?.horizon?.toFixed(2),

                        "acc_14_type":    products[i].accessories_model[13]?.type?.toFixed(0),
                        "acc_14_length":  products[i].accessories_model[13]?.length?.toFixed(2),
                        "acc_14_height":  products[i].accessories_model[13]?.height?.toFixed(2),
                        "acc_14_location": products[i].accessories_model[13]?.location?.toFixed(2),
                        "acc_14_horizon": products[i].accessories_model[13]?.horizon?.toFixed(2),

                        "acc_15_type":    products[i].accessories_model[14]?.type?.toFixed(0),
                        "acc_15_length":  products[i].accessories_model[14]?.length?.toFixed(2),
                        "acc_15_height":  products[i].accessories_model[14]?.height?.toFixed(2),
                        "acc_15_location": products[i].accessories_model[14]?.location?.toFixed(2),
                        "acc_15_horizon": products[i].accessories_model[14]?.horizon?.toFixed(2),

                        "acc_16_type":    products[i].accessories_model[15]?.type?.toFixed(0),
                        "acc_16_length":  products[i].accessories_model[15]?.length?.toFixed(2),
                        "acc_16_height":  products[i].accessories_model[15]?.height?.toFixed(2),
                        "acc_16_location": products[i].accessories_model[15]?.location?.toFixed(2),
                        "acc_16_horizon": products[i].accessories_model[15]?.horizon?.toFixed(2),
                        
                    }                    
                } else {
                    obj = {
                        "type": products[i].type.toFixed(0),
                        "length": products[i].length.toFixed(2),
                        "width": products[i].width.toFixed(2),
                        "height": products[i].height.toFixed(2),
                        "wing_length": products[i].wing_length.toFixed(2),
                        "wing_width": products[i].wing_width.toFixed(2),
                        "overhang": products[i].overhang.toFixed(2),
                        "rotate": products[i].rotate.toFixed(2),
                            
                        "x": products[i].position.x.toFixed(2),
                        "y": products[i].position.y.toFixed(2),
                        "z": products[i].position.z.toFixed(2),                              

                        "roofing": products[i].roofing.toFixed(0),
                        
                    }
                }                  
                array.push(obj)
            }             
            return JSON.stringify(array)
        },
        save: function() {
            try {      
            var content = gui_module.saveProduct.getProductsInfo()  
            console.log(content)
            const a = document.createElement('a');
            const file = new Blob([content], {type: 'text/plain'});

            a.href= URL.createObjectURL(file);
            a.download = saveFileName.Name + ".sk";
            a.click();

            URL.revokeObjectURL(a.href);  
            } catch (e) { }
        },
        loadProductInput1Call: function () {
            document.getElementById('loadProductInput1').click();
        },
        },    
    set_import_product_gui(gui_folder) {  
        gui_folder.add(gui_module.saveProduct, 'loadProductInput1Call').name('Load Product');
        const loadProductInput1 = document.getElementById("loadProductInput1");
        if(loadProductInput1) loadProductInput1.addEventListener("change", function() {
            var file = document.getElementById('loadProductInput1').files[0];
            document.getElementById('loadProductInput1').value = ''
            var fr = new FileReader()
            fr.readAsText(file)
            fr.onload = function (evt) {    
                var result = evt.target.result   
                var json = JSON.parse(result) 
                for(var i = 0; i < json.length; ++i) {
                    var obj
                    var type = parseInt(json[i].type)
                    if(type < 32 || (type > 63 && type < 256)) {
                        obj = {
                            type: json[i].type,
                            price_index: json[i].price_index,
                            length: json[i].length,
                            back_length: json[i].back_length,
                            projection: json[i].projection,
                            height: json[i].height,
                            step_length: json[i].step_length,
                            step_size: json[i].step_size,
                            bracket_size: json[i].bracket_size,
                            angle: json[i].angle,
                            rotate: json[i].rotate,
                            horizon: json[i].horizon,
                            front_overhang: json[i].front_overhang,
                            back_overhang: json[i].back_overhang,
                            step_overhang: json[i].step_overhang,
                            roofing_type: json[i].roofing_type,
                            gable_type: json[i].gable_type,
                            
                            symmetric: json[i].symmetric,
                            profile: json[i].profile,
                            fan: json[i].fan,
                            number: json[i].number,
                            landscaping_visible: json[i].landscaping_visible,
                            landscaping_type: json[i].landscaping_type,
                            landscaping_x_offset: json[i].landscaping_x_offset,
                            landscaping_z_offset: json[i].landscaping_z_offset,
    
                            x: json[i].x,
                            y: json[i].y,
                            z: json[i].z,
    
                            front_post_width: json[i].front_post_width,
                            forced_front_posts_no: json[i].forced_front_posts_no,
                            decking_post_width: json[i].decking_post_width,
                            forced_decking_posts_no: json[i].forced_decking_posts_no,
                            forced_brackets_no: json[i].forced_brackets_no,
                            forced_back_posts_no: json[i].forced_back_posts_no,
                            forced_right_posts_no: json[i].forced_right_posts_no,
                            forced_left_posts_no: json[i].forced_left_posts_no,
                            forced_rafters_no: json[i].forced_rafters_no,
                            forced_purlins_no: json[i].forced_purlins_no,

                            roofing: json[i].roofing,
                            gutters: json[i].gutters,
                            beams: json[i].beams,
                            posts: json[i].posts,
                            walls: json[i].walls,
                            roller_doors: json[i].rollerDoors,

                            model_type: json[i].model_type,
                            wind_category: json[i].wind_category,
                            roof_sheet_type: json[i].roof_sheet_type,
                            attachment_type: json[i].attachment_type,
                            attachment_sub_type: json[i].attachment_sub_type,
                            partial_attachment_type: json[i].partial_attachment_type,
                            attachment_top: json[i].attachment_top,
                            attachment_top_type: json[i].attachment_top_type,
                            attachment_top_fascia_type: json[i].attachment_top_fascia_type,
                            attachment_top_variation: json[i].attachment_top_variation,
                            attachment_right: json[i].attachment_right,
                            attachment_right_type: json[i].attachment_right_type,
                            attachment_right_fascia_type: json[i].attachment_right_fascia_type,
                            attachment_right_variation: json[i].attachment_right_variation,
                            attachment_left: json[i].attachment_left,
                            attachment_left_type: json[i].attachment_left_type,
                            attachment_left_fascia_type: json[i].attachment_left_fascia_type,
                            attachment_left_variation: json[i].attachment_left_variation,
                            attachment_bottom: json[i].attachment_bottom,
                            attachment_bottom_type: json[i].attachment_bottom_type,
                            attachment_bottom_fascia_type: json[i].attachment_bottom_fascia_type,
                            attachment_bottom_variation: json[i].attachment_bottom_variation,
                            fascia_type: json[i].fascia_type,
                            post_type: json[i].post_type,
                            gutter_type: json[i].gutter_type,
                            gutter_front: json[i].gutter_front,
                            gutter_back: json[i].gutter_back,
                            gutter_left: json[i].gutter_left,
                            gutter_right: json[i].gutter_right,
                            footing_type: json[i].footing_type,
                            bracket_type: json[i].bracket_type,
                            fascia_beam_type: json[i].fascia_beam_type,
                            purlin_beam_type: json[i].purlin_beam_type,
                            rafter_beam_type: json[i].rafter_beam_type,
                            downpipe_type: json[i].downpipe_type,
                            wind_options_importance_level: json[i].wind_options_importance_level,
                            wind_options_blocking: json[i].wind_options_blocking,
                            allow_RSBLC: json[i].allow_RSBLC,

                            front_posts_offset_1: json[i].front_posts_offset_1,
                            front_posts_offset_2: json[i].front_posts_offset_2,
                            front_posts_offset_3: json[i].front_posts_offset_3,
                            front_posts_offset_4: json[i].front_posts_offset_4,
                            front_posts_offset_5: json[i].front_posts_offset_5,
                            front_posts_offset_6: json[i].front_posts_offset_6,

                            back_posts_offset_1: json[i].back_posts_offset_1,
                            back_posts_offset_2: json[i].back_posts_offset_2,
                            back_posts_offset_3: json[i].back_posts_offset_3,
                            back_posts_offset_4: json[i].back_posts_offset_4,
                            back_posts_offset_5: json[i].back_posts_offset_5,
                            back_posts_offset_6: json[i].back_posts_offset_6,

                            right_posts_offset_1: json[i].right_posts_offset_1,
                            right_posts_offset_2: json[i].right_posts_offset_2,
                            right_posts_offset_3: json[i].right_posts_offset_3,
                            right_posts_offset_4: json[i].right_posts_offset_4,
                            right_posts_offset_5: json[i].right_posts_offset_5,
                            right_posts_offset_6: json[i].right_posts_offset_6,

                            left_posts_offset_1: json[i].left_posts_offset_1,
                            left_posts_offset_2: json[i].left_posts_offset_2,
                            left_posts_offset_3: json[i].left_posts_offset_3,
                            left_posts_offset_4: json[i].left_posts_offset_4,
                            left_posts_offset_5: json[i].left_posts_offset_5,
                            left_posts_offset_6: json[i].left_posts_offset_6,

                            decking_posts_offset_1: json[i].decking_posts_offset_1,
                            decking_posts_offset_2: json[i].decking_posts_offset_2,
                            decking_posts_offset_3: json[i].decking_posts_offset_3,
                            decking_posts_offset_4: json[i].decking_posts_offset_4,
                            decking_posts_offset_5: json[i].decking_posts_offset_5,
                            decking_posts_offset_6: json[i].decking_posts_offset_6,

                            bracket_offset_1: json[i].bracket_offset_1,
                            bracket_offset_2: json[i].bracket_offset_2,
                            bracket_offset_3: json[i].bracket_offset_3,
                            bracket_offset_4: json[i].bracket_offset_4,
                            bracket_offset_5: json[i].bracket_offset_5,
                            bracket_offset_6: json[i].bracket_offset_6, 
                            
                            bracket_length_1: json[i].bracket_length_1,
                            bracket_length_2: json[i].bracket_length_2,
                            bracket_length_3: json[i].bracket_length_3,
                            bracket_length_4: json[i].bracket_length_4,
                            bracket_length_5: json[i].bracket_length_5,
                            bracket_length_6: json[i].bracket_length_6,  

                            
                            rafter_offset_1: json[i].rafter_offset_1,
                            rafter_offset_2: json[i].rafter_offset_2,
                            rafter_offset_3: json[i].rafter_offset_3,
                            rafter_offset_4: json[i].rafter_offset_4,
                            rafter_offset_5: json[i].rafter_offset_5,
                            rafter_offset_6: json[i].rafter_offset_6, 

                            purlin_offset_1: json[i].purlin_offset_1,
                            purlin_offset_2: json[i].purlin_offset_2,
                            purlin_offset_3: json[i].purlin_offset_3,
                            purlin_offset_4: json[i].purlin_offset_4,
                            purlin_offset_5: json[i].purlin_offset_5,
                            purlin_offset_6: json[i].purlin_offset_6, 

                            acc_1_type:    json[i].acc_1_type,
                            acc_1_length:  json[i].acc_1_length,
                            acc_1_width:   json[i].acc_1_widht,
                            acc_1_height:  json[i].acc_1_height,
                            acc_1_location: json[i].acc_1_location,
                            acc_1_horizon: json[i].acc_1_horizon,

                            acc_2_type:    json[i].acc_2_type,
                            acc_2_length:  json[i].acc_2_length,
                            acc_2_width:   json[i].acc_2_widht,
                            acc_2_height:  json[i].acc_2_height,
                            acc_2_location: json[i].acc_2_location,
                            acc_2_horizon: json[i].acc_2_horizon,

                            acc_3_type:    json[i].acc_3_type,
                            acc_3_length:  json[i].acc_3_length,
                            acc_3_width:   json[i].acc_3_widht,
                            acc_3_height:  json[i].acc_3_height,
                            acc_3_location: json[i].acc_3_location,
                            acc_3_horizon: json[i].acc_3_horizon,

                            acc_4_type:    json[i].acc_4_type,
                            acc_4_length:  json[i].acc_4_length,
                            acc_4_width:   json[i].acc_4_widht,
                            acc_4_height:  json[i].acc_4_height,
                            acc_4_location: json[i].acc_4_location,
                            acc_4_horizon: json[i].acc_4_horizon,

                            acc_5_type:    json[i].acc_5_type,
                            acc_5_length:  json[i].acc_5_length,
                            acc_5_width:   json[i].acc_5_widht,
                            acc_5_height:  json[i].acc_5_height,
                            acc_5_location: json[i].acc_5_location,
                            acc_5_horizon: json[i].acc_5_horizon,

                            acc_6_type:    json[i].acc_6_type,
                            acc_6_length:  json[i].acc_6_length,
                            acc_6_width:   json[i].acc_6_widht,
                            acc_6_height:  json[i].acc_6_height,
                            acc_6_location: json[i].acc_6_location,
                            acc_6_horizon: json[i].acc_6_horizon,

                            acc_7_type:    json[i].acc_7_type,
                            acc_7_length:  json[i].acc_7_length,
                            acc_7_width:   json[i].acc_7_widht,
                            acc_7_height:  json[i].acc_7_height,
                            acc_7_location: json[i].acc_7_location,
                            acc_7_horizon: json[i].acc_7_horizon,

                            acc_8_type:    json[i].acc_8_type,
                            acc_8_length:  json[i].acc_8_length,
                            acc_8_width:   json[i].acc_8_widht,
                            acc_8_height:  json[i].acc_8_height,
                            acc_8_location: json[i].acc_8_location,
                            acc_8_horizon: json[i].acc_8_horizon,

                            acc_9_type:    json[i].acc_9_type,
                            acc_9_length:  json[i].acc_9_length,
                            acc_9_width:   json[i].acc_9_widht,
                            acc_9_height:  json[i].acc_9_height,
                            acc_9_location: json[i].acc_9_location,
                            acc_9_horizon: json[i].acc_9_horizon,

                            acc_10_type:    json[i].acc_10_type,
                            acc_10_length:  json[i].acc_10_length,
                            acc_10_width:   json[i].acc_10_widht,
                            acc_10_height:  json[i].acc_10_height,
                            acc_10_location: json[i].acc_10_location,
                            acc_10_horizon: json[i].acc_10_horizon,

                            acc_11_type:    json[i].acc_11_type,
                            acc_11_length:  json[i].acc_11_length,
                            acc_11_width:   json[i].acc_11_widht,
                            acc_11_height:  json[i].acc_11_height,
                            acc_11_location: json[i].acc_11_location,
                            acc_11_horizon: json[i].acc_11_horizon,

                            acc_12_type:    json[i].acc_12_type,
                            acc_12_length:  json[i].acc_12_length,
                            acc_12_width:   json[i].acc_12_widht,
                            acc_12_height:  json[i].acc_12_height,
                            acc_12_location: json[i].acc_12_location,
                            acc_12_horizon: json[i].acc_12_horizon,

                            acc_13_type:    json[i].acc_13_type,
                            acc_13_length:  json[i].acc_13_length,
                            acc_13_width:   json[i].acc_13_widht,
                            acc_13_height:  json[i].acc_13_height,
                            acc_13_location: json[i].acc_13_location,
                            acc_13_horizon: json[i].acc_13_horizon,

                            acc_14_type:    json[i].acc_14_type,
                            acc_14_length:  json[i].acc_14_length,
                            acc_14_width:   json[i].acc_14_widht,
                            acc_14_height:  json[i].acc_14_height,
                            acc_14_location: json[i].acc_14_location,
                            acc_14_horizon: json[i].acc_14_horizon,

                            acc_15_type:    json[i].acc_15_type,
                            acc_15_length:  json[i].acc_15_length,
                            acc_15_width:   json[i].acc_15_widht,
                            acc_15_height:  json[i].acc_15_height,
                            acc_15_location: json[i].acc_15_location,
                            acc_15_horizon: json[i].acc_15_horizon,

                            acc_16_type:    json[i].acc_16_type,
                            acc_16_length:  json[i].acc_16_length,
                            acc_16_width:   json[i].acc_16_widht,
                            acc_16_height:  json[i].acc_16_height,
                            acc_16_location: json[i].acc_16_location, 
                            acc_16_horizon: json[i].acc_16_horizon,                           
                        }
                        product_manager_module.add_from_obj(obj)
                    } else if(type < 34) {
                        obj = {
                            type: json[i].type,
                            length: json[i].length,
                            width: json[i].width,
                            height: json[i].height,
                            wing_length: json[i].wing_length,
                            wing_width: json[i].wing_width,
                            overhang: json[i].overhang,
                            rotate: json[i].rotate,
                            x: json[i].x,
                            y: json[i].y,
                            z: json[i].z,
        
                            roofing: json[i].roofing,
                        }
                        product_manager_module.add_from_obj(obj)
                    }  else if(type == 1025) {
                        project_specification.first_name = json[i].first_name
                        project_specification.family_name = json[i].family_name
                        project_specification.email = json[i].email
                        project_specification.phone_no = json[i].phone_no
                        project_specification.build_street_no = json[i].build_street_no
                        project_specification.build_street = json[i].build_street
                        project_specification.build_suburb = json[i].build_suburb
                        project_specification.build_post_code = json[i].build_post_code
                        project_specification.build_state = json[i].build_state
                        project_specification.creation_date = json[i].create_date
                        project_specification.update_date = json[i].update_date
                        project_specification.quote_type = json[i].quot_type
                        project_specification.comment = json[i].comment
                    }                   
                }  
                project_specification.call() 
            }
            fr.onerror = function (evt) {
                console.log("Error")
            }            

        }, false);

        var saveFileName = {
            Name: "?"
        };
     
        gui_folder.add(saveFileName, "Name").onFinishChange(function (value) {
            saveFileName.Name = value;
          });
      
        gui_folder.add(gui_module.saveProduct, "save").name("Save Product");
    },
    customize_gui: function(type) {
        selectGUISubmenu(type)
        gui_module.set_dim_text()
        colorSetType(type)
    },
    remove: function() {      
        product_manager_module.remove(gui_module.index - 1)
        gui_module.index_gui.setValue(gui_module.index - 1)
        var product = product_manager_module.products[gui_module.index - 1];
        if(product) {
            if(product.type < 31) {   
                gui_module.customize_gui(product.price_index)
            } else if(product.type > 63) {
                shed_module.set_product(product, true)  
                gui_module.set_dim_text()              
            } else {
                gui_module.customize_gui(32)
            }
        } else {
            gui_module.set_dim_text()
        }
        updatePrice()
    },
    set_index: function(informSelect) {
        if(gui_module.index >= product_manager_module.products.length) {
            gui_module.index = product_manager_module.products.length;                  
        }
        if(gui_module.index < 1) gui_module.index = 1;     
        
        gui_module.set_gui_parameters(informSelect);        
    },
    set_gui_parameters_by_product: function(product) {
        gui_module.length_gui.setValue(product.length);
        gui_module.projection_gui.setValue(product.projection);
        gui_module.height_gui.setValue(product.height);
        gui_module.pitch_length_gui.setValue(product.length);
        gui_module.pitch_projection_gui.setValue(product.projection);
        gui_module.pitch_height_gui.setValue(product.height);
        gui_module.angled_length_gui.setValue(product.length);
        gui_module.angled_back_length_gui.setValue(product.back_length);
        gui_module.angled_projection_gui.setValue(product.projection);
        gui_module.angled_height_gui.setValue(product.height);  
        gui_module.landing_length_gui.setValue(product.length);        
        gui_module.landing_projection_gui.setValue(product.projection);
        gui_module.landing_height_gui.setValue(product.height);   
        gui_module.landing_step_size_gui.setValue(product.step_size - 3);    
        gui_module.horizon_gui.setValue(product.horizon);
        gui_module.step_length_gui.setValue(product.step_length);
        gui_module.step_size_gui.setValue(product.step_size);        
                
        gui_module.angle_gui.setValue(product.angle);
        gui_module.view_gui.setValue(product.rotate);
        gui_module.symmetric_gui.setValue(product.symmetric);
        gui_module.downlight_gui.setValue(product.number)

        gui_module.landscaping_visible_gui.setValue(product.landscaping_visible);
        //gui_module.landscaping_type_gui.setValue(product.landscaping_type);
        gui_module.landscaping_x_offset_gui.setValue(product.landscaping_x_offset);
        gui_module.landscaping_z_offset_gui.setValue(product.landscaping_z_offset);

        gui_module.product_lock_gui.setValue(product.lock);

        if(product.type != 32 && product.type != 33) {
            for(var i = 0; i < gui_module.rafter_offset_gui.length; ++i) {
                gui_module.rafter_offset_gui[i]?.setValue(parseInt(product.rafter_offset[i] * 100))
            }
            for(var i = 0; i < gui_module.purlin_offset_gui.length; ++i) {
                gui_module.purlin_offset_gui[i]?.setValue(parseInt(product.purlin_offset[i] * 100))
            }
        }
        /*for(var i = 0; i < gui_module.bracket_length_gui.length; ++i) {
            gui_module.bracket_length_gui[i].setValue(product.bracket_length[i])
        }*/
    },
    set_gui_parameters: function(informSelect) {
        var product = product_manager_module.products[gui_module.index - 1];          
        if(product == null) return
        if(gui_module.index_buffer != gui_module.index - 1) {
            if(product.type < 31) {                
                gui_module.set_gui_parameters_by_product(product)
                gui_module.customize_gui(product.price_index)
            } else if(product.type > 63) {
                shed_module.set_product(product)
                gui_module.customize_gui(product.type)
            } else {
                building_module.set_product(product)
                gui_module.customize_gui(32)
            }
            gui_module.index_buffer = gui_module.index - 1
            informSelect()
        }             
        product_manager_module.set_cursor(product_manager_module.products[gui_module.index - 1])
    },
    set_color_gui() {
        var smartkitsColorSelectFolder = gui_module.gui.addFolder('Choose Color');   
        gui_module.roofingColorElement = smartkitsColorSelectFolder.add(gui_module, 'roofing', 1, 21, 1).onChange(function() {            
            product_manager_module.roofing_color(gui_module.index - 1, gui_module.roofing);
        },);   
        gui_module.gutterColorElement = smartkitsColorSelectFolder.add(gui_module, 'gutters', 1, 21, 1).onChange(function() {            
            product_manager_module.gutters_color(gui_module.index - 1, gui_module.gutters);
        });
        gui_module.beamColorElement = smartkitsColorSelectFolder.add(gui_module, 'beams', 1, 21, 1).onChange(function() {            
            product_manager_module.beams_color(gui_module.index - 1, gui_module.beams);
        });
        gui_module.postColorElement = smartkitsColorSelectFolder.add(gui_module, 'posts', 1, 21, 1).onChange(function() {            
            product_manager_module.posts_color(gui_module.index - 1, gui_module.posts);
        });
        gui_module.wallColorElement = smartkitsColorSelectFolder.add(gui_module, 'walls', 1, 21, 1).onChange(function() {            
            product_manager_module.walls_color(gui_module.index - 1, gui_module.walls);
        });
        gui_module.rollerDoorColorElement = smartkitsColorSelectFolder.add(gui_module, 'roller_doors', 1, 21, 1).onChange(function() {            
            product_manager_module.rollerDoors_color(gui_module.index - 1, gui_module.roller_doors);
        });
        hideElement(smartkitsColorSelectFolder.domElement, true)
    },    
    product_type: 'insulated_flyover',
    shed_type: 'single_garage',
    building_gui: null,
    shed_gui: null,
    choose_size_gui: null,
    symmetric_gui: null,
    horizon_gui: null,
    symmetric: false,
    Price: false,
    lock_index: false,
    scale: 1,

    setup_size_color_gui: function(informSelect) {
        var select_product_gui = gui_module.gui.addFolder('Choose Product');
        
        const productTypes = (function () {
            return {
              insulated_flyover: "Insulated Flyover",
              non_insulated_flyover: "Non Insulated Flyover",
              insulated_patio_attached: "Insulated Attached Patio",
              non_insulated_patio_attached: "Non Insulated Attached Patio",
              insulated_patio_freestanding: "Insulated Freestanding Patio",              
              non_insulated_patio_freestanding: "Non Insulated Freestanding Patio",              
              hip: "Hip",
              hip_attached: "Hip Attached",
              insulated_gable: "Insulated Gable",
              non_insulated_gable: "Non Insulated Gable",
              insulated_gable_attached: "Insulated Gable Attached",              
              non_insulated_gable_attached: "Non Insulated Gable Attached",
              dutch: "Dutch",
              dutch_attached: "Dutch Attached",
              gazebo_6_sided: "Gazebo 6 Sided",
              gazebo_8_sided: "Gazebo 8 Sided",
              ground_level_decking: "Ground Level Decking",
              high_set_decking: "High Set Decking",
              patio_carport: "Patio Carport",              
              dutch_carport: "Dutch Carport",
              gable_carport: "Gable Carport",
              hip_carport: "Hip Carport",
              caravan_boatport: "Caravan Boatport",
              angled_attached: "Angled Attached",
              angled_freestanding: "Angled Freestanding",
              decking_landing: "Decking Landing",
              building_left: "Building Left",
              building_right: "Building Right",
              building: "Building"
            };
        })();
      
        const productTypeKeys = getObjectsKeys(productTypes);
        
        //
        const shedTypes = (function () {
            return {
              single_garage: "Single Garage",   
              double_garage: "Double Garage",
              triple_garage: "Triple Garage",
              skillion_garage: "Skillion Garage",
              hip_garage: "Hip Garage",
              dutch_garage: "Dutch Garage",              
              gara_port: "Gara Port",
              building_left: "Building Left",
              building_right: "Building Right",
              building: "Building"           
            };
        })();
      
        const shedTypeKeys = getObjectsKeys(shedTypes);
       
        if(gui_module.user_access == 'ezyblox_auth') {
            var shedTypeGUI = select_product_gui.add(gui_module, 'product_type', shedTypeKeys).onChange(function() {   
            });        
            shedTypeGUI.domElement.id = 'shedTypeGUI';
            shedTypeGUI.setValue('single_garage')
        } else {
            var productTypeGUI = select_product_gui.add(gui_module, 'product_type', productTypeKeys).onChange(function() { 
            });        
            productTypeGUI.domElement.id = 'productTypeGUI';
        }        
        //
        select_product_gui.add(this, "add").name('Add');

        select_product_gui.add(this, 'remove').name('Remove');

        
        var index_folder_gui = gui_module.gui.addFolder('Index');
        gui_module.index_gui = index_folder_gui.add(this, 'index', 1, product_manager_module.maximum, 1).onChange(function() {
            if(gui_module.lock_index) gui_module.index = gui_module.index_buffer + 1
            else gui_module.set_index(informSelect)
        })
        index_folder_gui.add(gui_module, 'lock_index').onChange()
        gui_module.set_import_product_gui(select_product_gui);
        select_product_gui.add(gui_module, "Price").onChange(function() {
            updatePrice()
        });
        updatePrice()

        gui_module.add_flyover_choose_size_gui();
        gui_module.add_pitch_choose_size_gui();
        gui_module.add_angled_choose_size_gui();
        gui_module.add_landing_choose_size_gui();
        
        gui_module.setup_gui = gui_module.gui.addFolder('Setup');
        gui_module.product_lock_gui = gui_module.setup_gui.add(gui_module, "lock").onChange(function() {
            product_manager_module.lock(gui_module.index - 1, gui_module.lock);
        });        
        /*gui_module.setup_gui.add(this, 'scale', 1, 20, 0.1).onChange(function() {
            sceneItems.scene.scale.set(gui_module.scale, gui_module.scale, gui_module.scale)
        });*/
        gui_module.setup_gui.add(gui_module, "reset").name("Reset Location");
        gui_module.angle_gui = gui_module.setup_gui.add(this, 'angle', -45, 45, 1).onChange(function() {
            product_manager_module.angle(gui_module.index - 1, gui_module.angle);
        });
        gui_module.view_gui = gui_module.setup_gui.add(this, 'rotate', 0, 360, 1).onChange(function() {
            product_manager_module.rotate(gui_module.index - 1, gui_module.rotate);
        });

        gui_module.horizon_gui = gui_module.setup_gui.add(this, 'altitude', 0, 6, 0.1).onChange(function () {
            product_manager_module.set_horizon(gui_module.index - 1, gui_module.altitude)
        });

        gui_module.symmetric_gui = gui_module.setup_gui.add(gui_module, "symmetric").onChange(function() {
            product_manager_module.set_symmetric(gui_module.index - 1, gui_module.symmetric)
        });

        const roofingProfiles = (function () {
            return {
              TrimDeck: "Trim Deck",
              FlatDeck: "Flat Deck",
              SpanDeck: "Span Deck",
              CustomOrb: "Custom Orb",
              CustomOrb_Ins: "Custom Orb Insulated",
              PlaneDeck: "Plane Deck",
            };
        })();
      
        const roofingProfileKeys = getObjectsKeys(roofingProfiles);
      
        const roofingProfilesData = {
           roofing_profile: roofingProfileKeys[0],
        };      
      
        var roofingProfileGUI = gui_module.setup_gui.add(roofingProfilesData, 'roofing_profile', roofingProfileKeys).onChange(function() {
            if(roofingProfilesData.roofing_profile == "TrimDeck") {                 
                gui_module.profile = 0       
            } else if(roofingProfilesData.roofing_profile == "FlatDeck") {                 
                gui_module.profile = 1       
            } else if(roofingProfilesData.roofing_profile == "PlaneDeck") {                 
                gui_module.profile = 2       
            } else if(roofingProfilesData.roofing_profile == "CustomOrb") {                 
                gui_module.profile = 10       
            } else if(roofingProfilesData.roofing_profile == "CustomOrb_Ins") {                 
                gui_module.profile = 11       
            } else if(roofingProfilesData.roofing_profile == "SpanDeck") {                 
                gui_module.profile = 12       
            } else {                
                gui_module.profile = 10
            } 
            product_manager_module.profile(gui_module.index - 1, gui_module.profile);
        });
    
        roofingProfileGUI.domElement.id = 'roofingProfileGUI';

        gui_module.front_overhang_gui = gui_module.setup_gui.add(this, 'front_overhang', 1, 50, 1).onChange(function() {
            product_manager_module.front_overhang(gui_module.index - 1, gui_module.front_overhang / 100 );
        });        
        gui_module.back_overhang_gui = gui_module.setup_gui.add(this, 'back_overhang',  0, 50, 1).onChange(function() {
            product_manager_module.back_overhang(gui_module.index - 1, gui_module.back_overhang / 100 );
        });
        gui_module.step_overhang_gui = gui_module.setup_gui.add(this, 'step_overhang',  0, 50, 1).onChange(function() {
            product_manager_module.step_overhang(gui_module.index - 1, gui_module.step_overhang / 100 );
        });

        const gableTypes = (function () {
            return {
              Close: "Close",
              Half_Close: "Half Closed",
              Open: "Open"
            };
        })();
      
        const gableTypeKeys = getObjectsKeys(gableTypes);
      
        const gableTypesData = {
            gable_type: gableTypeKeys[0],
        };      
      
        var gableTypeGUI = gui_module.setup_gui.add(gableTypesData, 'gable_type', gableTypeKeys).onChange(function() {
            if(gableTypesData.gable_type == "Close") {                 
                gui_module.gable_type = 0       
            } else if(gableTypesData.gable_type == "Half_Close") {                 
                gui_module.gable_type = 1       
            } else if(gableTypesData.gable_type == "Open") {                 
                gui_module.gable_type = 2       
            } 
            product_manager_module.gable_type(gui_module.index - 1, gui_module.gable_type)
        });
    
        gableTypeGUI.domElement.id = 'gableTypeGUI'

        gui_module.setup_posts_gui()
        var fan_folder_gui = gui_module.setup_gui.addFolder('Fan');
        gui_module.fan_gui = fan_folder_gui.add(gui_module, "fan").onChange(function() {
            product_manager_module.set_fan(gui_module.index - 1, gui_module.fan)
        });
        fan_folder_gui.addColor(gui_module, 'color').onChange(function() { 
            product_manager_module.set_fan_color(gui_module.index - 1, gui_module.color)
        });   

        var downlight_folder_gui = gui_module.setup_gui.addFolder('Downlights');
        gui_module.downlight_gui = downlight_folder_gui.add(gui_module, 'number', 0, 10, 1).onChange(function() {
            product_manager_module.set_downlights_number(gui_module.index - 1, gui_module.number)
        });
        gui_module.set_landscaping_gui(gui_module.setup_gui)        
        gui_module.building_gui = building_module.add_gui(gui_module.gui)
        gui_module.shed_gui = shed_module.add_gui(gui_module.gui)

        if(gui_module.user_access == 'ezyblox_auth') gui_module.customize_gui(64)       
        else gui_module.customize_gui(1)       
    },

    add_flyover_choose_size_gui() {
        gui_module.choose_size_gui  = gui_module.gui.addFolder('Choose Patio Size');
        gui_module.length_gui = gui_module.choose_size_gui.add(this, 'length', 1, 32, 0.1).onChange(function() {            
            gui_module.validate_step_length()
            product_manager_module.length(gui_module.index - 1, gui_module.length);
            updatePrice()
        });
        gui_module.projection_gui = gui_module.choose_size_gui.add(this, 'projection', 1, 20, 0.1).onChange(function() {
            product_manager_module.projection(gui_module.index - 1, gui_module.projection);   
            updatePrice()         
        });
        gui_module.height_gui = gui_module.choose_size_gui.add(this, 'height', 0.2, 8, 0.1).onChange(function() {
            product_manager_module.height(gui_module.index - 1, gui_module.height);            
        });  
        gui_module.step_length_gui = gui_module.choose_size_gui.add(this, 'step_length', 0, 31, 1).onChange(function() {
            gui_module.validate_step_length()
            product_manager_module.step_length(gui_module.index - 1, gui_module.step_length);
            updatePrice()
        }); 
        gui_module.step_size_gui = gui_module.choose_size_gui.add(this, 'step_size', 3, 19, 0.1).onChange(function() {
            product_manager_module.step_size(gui_module.index - 1, gui_module.step_size);
            updatePrice()
        }); 
 
    },
    pitch_choose_size_gui: null,
    pitch_length_gui: null,
    pitch_projection_gui: null,
    pitch_height_gui: null,
    add_pitch_choose_size_gui() {
        gui_module.pitch_choose_size_gui  = gui_module.gui.addFolder('Choose Size');
        gui_module.pitch_length_gui = gui_module.pitch_choose_size_gui.add(this, 'length', 1, 32, 0.1).onChange(function() {            
            gui_module.validate_step_length()
            product_manager_module.length(gui_module.index - 1, gui_module.length);
            updatePrice()
        });
        gui_module.pitch_projection_gui = gui_module.pitch_choose_size_gui.add(this, 'projection', 1, 20, 0.1).onChange(function() {
            product_manager_module.projection(gui_module.index - 1, gui_module.projection);   
            updatePrice()         
        });
        gui_module.pitch_height_gui = gui_module.pitch_choose_size_gui.add(this, 'height', 0.2, 8, 0.1).onChange(function() {
            product_manager_module.height(gui_module.index - 1, gui_module.height);            
        });  
        /*gui_module.pitch_choose_size_gui.add(this, 'step_size', 1, 100, 1).onChange(function() {
            product_manager_module.step_size(gui_module.index - 1, gui_module.step_size);
            updatePrice()
        });*/ 
    },
    angled_choose_size_gui: null,
    angled_back_length_gui: null,    
    angled_length_gui: null,
    angled_projection_gui: null,
    angled_height_gui: null,
    add_angled_choose_size_gui() {
        gui_module.angled_choose_size_gui  = gui_module.gui.addFolder('Choose Angled Size');
        gui_module.angled_length_gui = gui_module.angled_choose_size_gui.add(this, 'length', 1, 32, 0.1).onChange(function() {            
            gui_module.validate_step_length()
            product_manager_module.length(gui_module.index - 1, gui_module.length);
            updatePrice()
        });
        gui_module.angled_back_length_gui = gui_module.angled_choose_size_gui.add(this, 'back_length', 1, 32, 0.1).onChange(function() {            
            gui_module.validate_step_length()
            product_manager_module.back_length(gui_module.index - 1, gui_module.back_length);
            updatePrice()
        });
        gui_module.angled_projection_gui = gui_module.angled_choose_size_gui.add(this, 'projection', 1, 32, 0.1).onChange(function() {
            product_manager_module.projection(gui_module.index - 1, gui_module.projection);   
            updatePrice()         
        });
        gui_module.angled_height_gui = gui_module.angled_choose_size_gui.add(this, 'height', 0.2, 8, 0.1).onChange(function() {
            product_manager_module.height(gui_module.index - 1, gui_module.height);            
        });  
    },
    landing_choose_size_gui: null, 
    landing_length_gui: null,
    landing_projection_gui: null,
    landing_height_gui: null,
    landing_step_size_gui: null,
    step_no: 0,
    add_landing_choose_size_gui() {
        gui_module.landing_choose_size_gui  = gui_module.gui.addFolder('Choose Landing Size');
        gui_module.landing_length_gui = gui_module.landing_choose_size_gui.add(this, 'length', 1, 16, 0.1).onChange(function() {            
            gui_module.validate_step_length()
            product_manager_module.length(gui_module.index - 1, gui_module.length);
            updatePrice()
        });        
        gui_module.landing_projection_gui = gui_module.landing_choose_size_gui.add(this, 'projection', 1, 16, 0.1).onChange(function() {
            product_manager_module.projection(gui_module.index - 1, gui_module.projection);   
            updatePrice()         
        });
        gui_module.landing_height_gui = gui_module.landing_choose_size_gui.add(this, 'height', 0.2, 8, 0.1).onChange(function() {
            product_manager_module.height(gui_module.index - 1, gui_module.height);            
        }); 
        gui_module.landing_step_size_gui = gui_module.landing_choose_size_gui.add(this, 'step_no', 0, 20, 1).onChange(function() {
            product_manager_module.step_size(gui_module.index - 1, gui_module.step_no + 3);
            updatePrice()
        });  
    },
 
    add_from_database: function() {        
        product_manager_module.add(28)
        gui_module.landscaping_visible_gui.setValue(false)
    },
    setup_posts_gui: function() {
        var main_setup_posts_folder_gui = gui_module.setup_gui.addFolder('Posts Setup');
        gui_module.front_post_offset_folder_gui = main_setup_posts_folder_gui.addFolder('Front');

        const postTypes = (function () {
            return {
              metal: "Metal",
              wide_metal: "Wide Metal",
              wood: "Wood",
              wide_wood: "Wide Wood",
            };
        })();
      
        const postTypeKeys = getObjectsKeys(postTypes);
      
        const postTypesData = {
           post_type: postTypeKeys[0],
        };      
      
        var postTypeGUI = gui_module.front_post_offset_folder_gui.add(postTypesData, 'post_type', postTypeKeys).onChange(function() {
            if(postTypesData.post_type == "metal") {                 
                gui_module.front_post_width = 0.08;        
            } else if(postTypesData.post_type == "wide_metal") {                 
                gui_module.front_post_width = 0.16;        
            } else if(postTypesData.post_type == "wood") {                
                gui_module.front_post_width = 0.125;           
            } else {
                gui_module.front_post_width = 0.15;                 
            } 
            product_manager_module.front_post_width(gui_module.index - 1, gui_module.front_post_width)
        });
    
        postTypeGUI.domElement.id = 'postTypeGUI';
    
        const posts_no = (function () {
            return {
              automatic: "Automatic",
              zero: "9",
              one: "1",
              two: "2",
              three: "3",
              four: "4",
              five: "5",
              six: "6"
            };
        })();
      
        const postNoKeys = getObjectsKeys(posts_no);
      
        const postsNoData = {
           post_no: postNoKeys[0],
        };      
      
        var postsNoGUI = gui_module.front_post_offset_folder_gui.add(postsNoData, 'post_no', postNoKeys).onChange(function() {
            if(postsNoData.post_no == "automatic") {
                gui_module.forced_front_posts_no = 0;   
            } else {
                gui_module.forced_front_posts_no = parseInt(posts_no[postsNoData.post_no]);
            } 
            product_manager_module.forced_front_posts_no(gui_module.index - 1, gui_module.forced_front_posts_no)
        });
    
        postsNoGUI.domElement.id = 'postsNoGUI';

        
        var _gui;
        _gui = gui_module.front_post_offset_folder_gui.add(this, 'f_p_1_offset', -50, 50, 1).onChange(function() {
            product_manager_module.front_post_offset(0, gui_module.index - 1, gui_module.f_p_1_offset / 100);
        });
        gui_module.front_post_offset_gui.push(_gui);
        _gui = gui_module.front_post_offset_folder_gui.add(this, 'f_p_2_offset', -50, 50, 1).onChange(function() {
            product_manager_module.front_post_offset(1, gui_module.index - 1, gui_module.f_p_2_offset / 100);
        });
        gui_module.front_post_offset_gui.push(_gui);
        _gui = gui_module.front_post_offset_folder_gui.add(this, 'f_p_3_offset', -50, 50, 1).onChange(function() {
            product_manager_module.front_post_offset(2, gui_module.index - 1, gui_module.f_p_3_offset / 100);
        });
        gui_module.front_post_offset_gui.push(_gui);
        _gui = gui_module.front_post_offset_folder_gui.add(this, 'f_p_4_offset', -50, 50, 1).onChange(function() {
            product_manager_module.front_post_offset(3, gui_module.index - 1, gui_module.f_p_4_offset / 100);
        });
        gui_module.front_post_offset_gui.push(_gui);
        _gui = gui_module.front_post_offset_folder_gui.add(this, 'f_p_5_offset', -50, 50, 1).onChange(function() {
            product_manager_module.front_post_offset(4, gui_module.index - 1, gui_module.f_p_5_offset / 100);
        });
        gui_module.front_post_offset_gui.push(_gui);
        _gui = gui_module.front_post_offset_folder_gui.add(this, 'f_p_6_offset', -50, 50, 1).onChange(function() {
            product_manager_module.front_post_offset(5, gui_module.index - 1, gui_module.f_p_6_offset / 100);
        });
        gui_module.front_post_offset_gui.push(_gui);

        gui_module.back_post_offset_folder_gui = main_setup_posts_folder_gui.addFolder('Back');
        const backPostNoKeys = getObjectsKeys(posts_no);
    
        const backPostNoData = {
            forced_back_posts_no: backPostNoKeys[0],
        };      
    
        var backPostsNoGUI = gui_module.back_post_offset_folder_gui.add(backPostNoData, 'forced_back_posts_no', backPostNoKeys).onChange(function() {
            if(backPostNoData.forced_back_posts_no == "automatic") {
                gui_module.forced_back_posts_no = 0  
            } else { 
                gui_module.forced_back_posts_no =  parseInt(posts_no[backPostNoData.forced_back_posts_no]);   
            } 
            product_manager_module.forced_back_posts_no(gui_module.index - 1, gui_module.forced_back_posts_no)    
        });  
        backPostsNoGUI.domElement.id = 'backPostsNoGUI'; 

        //var _gui;
        _gui = gui_module.back_post_offset_folder_gui.add(this, 'b_p_1_offset', -50, 50, 1).onChange(function() {
            product_manager_module.back_post_offset(0, gui_module.index - 1, gui_module.b_p_1_offset / 100);
        });
        gui_module.back_post_offset_gui.push(_gui);
        _gui = gui_module.back_post_offset_folder_gui.add(this, 'b_p_2_offset', -50, 50, 1).onChange(function() {
            product_manager_module.back_post_offset(1, gui_module.index - 1, gui_module.b_p_2_offset / 100);
        });
        gui_module.back_post_offset_gui.push(_gui);
        _gui = gui_module.back_post_offset_folder_gui.add(this, 'b_p_3_offset', -50, 50, 1).onChange(function() {
            product_manager_module.back_post_offset(2, gui_module.index - 1, gui_module.b_p_3_offset / 100);
        });
        gui_module.back_post_offset_gui.push(_gui);
        _gui = gui_module.back_post_offset_folder_gui.add(this, 'b_p_4_offset', -50, 50, 1).onChange(function() {
            product_manager_module.back_post_offset(3, gui_module.index - 1, gui_module.b_p_4_offset / 100);
        });
        gui_module.back_post_offset_gui.push(_gui);
        _gui = gui_module.back_post_offset_folder_gui.add(this, 'b_p_5_offset', -50, 50, 1).onChange(function() {
            product_manager_module.back_post_offset(4, gui_module.index - 1, gui_module.b_p_5_offset / 100);
        });
        gui_module.back_post_offset_gui.push(_gui);
        _gui = gui_module.back_post_offset_folder_gui.add(this, 'b_p_6_offset', -50, 50, 1).onChange(function() {
            product_manager_module.back_post_offset(5, gui_module.index - 1, gui_module.b_p_6_offset / 100);
        });
        gui_module.back_post_offset_gui.push(_gui);
        // right
        gui_module.right_post_offset_folder_gui = main_setup_posts_folder_gui.addFolder('Third Side');
        const rightPostNoKeys = getObjectsKeys(posts_no);
    
        const rightPostNoData = {
            posts_no: rightPostNoKeys[0],
        };      
    
        var rightPostsNoGUI = gui_module.right_post_offset_folder_gui.add(rightPostNoData, 'posts_no', rightPostNoKeys).onChange(function() {
            if(rightPostNoData.posts_no == "automatic") {
                gui_module.forced_right_posts_no = 0  
            } else { 
                gui_module.forced_right_posts_no =  parseInt(posts_no[rightPostNoData.posts_no]);   
            } 
            product_manager_module.forced_right_posts_no(gui_module.index - 1, gui_module.forced_right_posts_no)    
        });  
        rightPostsNoGUI.domElement.id = 'rightPostsNoGUI'; 

        //var _gui;
        _gui = gui_module.right_post_offset_folder_gui.add(this, 'r_p_1_offset', -50, 50, 1).onChange(function() {
            product_manager_module.right_post_offset(0, gui_module.index - 1, gui_module.r_p_1_offset / 100);
        });
        gui_module.right_post_offset_gui.push(_gui);
        _gui = gui_module.right_post_offset_folder_gui.add(this, 'r_p_2_offset', -50, 50, 1).onChange(function() {
            product_manager_module.right_post_offset(1, gui_module.index - 1, gui_module.r_p_2_offset / 100);
        });
        gui_module.right_post_offset_gui.push(_gui);
        _gui = gui_module.right_post_offset_folder_gui.add(this, 'r_p_3_offset', -50, 50, 1).onChange(function() {
            product_manager_module.right_post_offset(2, gui_module.index - 1, gui_module.r_p_3_offset / 100);
        });
        gui_module.right_post_offset_gui.push(_gui);
        _gui = gui_module.right_post_offset_folder_gui.add(this, 'r_p_4_offset', -50, 50, 1).onChange(function() {
            product_manager_module.right_post_offset(3, gui_module.index - 1, gui_module.r_p_4_offset / 100);
        });
        gui_module.right_post_offset_gui.push(_gui);
        _gui = gui_module.right_post_offset_folder_gui.add(this, 'r_p_5_offset', -50, 50, 1).onChange(function() {
            product_manager_module.right_post_offset(4, gui_module.index - 1, gui_module.r_p_5_offset / 100);
        });
        gui_module.right_post_offset_gui.push(_gui);
        _gui = gui_module.right_post_offset_folder_gui.add(this, 'r_p_6_offset', -50, 50, 1).onChange(function() {
            product_manager_module.right_post_offset(5, gui_module.index - 1, gui_module.r_p_6_offset / 100);
        });
        gui_module.right_post_offset_gui.push(_gui);     
        // left
        gui_module.left_post_offset_folder_gui = main_setup_posts_folder_gui.addFolder('Left');
        hideElement(gui_module.left_post_offset_folder_gui.domElement, true)
        const leftPostNoKeys = getObjectsKeys(posts_no);
    
        const leftPostNoData = {
            forced_left_posts_no: leftPostNoKeys[0],
        };      
    
        var leftPostsNoGUI = gui_module.left_post_offset_folder_gui.add(leftPostNoData, 'forced_left_posts_no', leftPostNoKeys).onChange(function() {
            if(leftPostNoData.forced_left_posts_no == "automatic") {
                gui_module.forced_left_posts_no = 0  
            } else { 
                gui_module.forced_left_posts_no =  parseInt(posts_no[leftPostNoData.forced_left_posts_no]);   
            } 
            product_manager_module.forced_left_posts_no(gui_module.index - 1, gui_module.forced_left_posts_no)    
        });  
        leftPostsNoGUI.domElement.id = 'leftPostsNoGUI'; 

        //var _gui;
        _gui = gui_module.left_post_offset_folder_gui.add(this, 'l_p_1_offset', -50, 50, 1).onChange(function() {
            product_manager_module.left_post_offset(0, gui_module.index - 1, gui_module.l_p_1_offset / 100);
        });
        gui_module.left_post_offset_gui.push(_gui);
        _gui = gui_module.left_post_offset_folder_gui.add(this, 'l_p_2_offset', -50, 50, 1).onChange(function() {
            product_manager_module.left_post_offset(1, gui_module.index - 1, gui_module.l_p_2_offset / 100);
        });
        gui_module.left_post_offset_gui.push(_gui);
        _gui = gui_module.left_post_offset_folder_gui.add(this, 'l_p_3_offset', -50, 50, 1).onChange(function() {
            product_manager_module.left_post_offset(2, gui_module.index - 1, gui_module.l_p_3_offset / 100);
        });
        gui_module.left_post_offset_gui.push(_gui);
        _gui = gui_module.left_post_offset_folder_gui.add(this, 'l_p_4_offset', -50, 50, 1).onChange(function() {
            product_manager_module.left_post_offset(3, gui_module.index - 1, gui_module.l_p_4_offset / 100);
        });
        gui_module.left_post_offset_gui.push(_gui);
        _gui = gui_module.left_post_offset_folder_gui.add(this, 'l_p_5_offset', -50, 50, 1).onChange(function() {
            product_manager_module.left_post_offset(4, gui_module.index - 1, gui_module.l_p_5_offset / 100);
        });
        gui_module.left_post_offset_gui.push(_gui);
        _gui = gui_module.left_post_offset_folder_gui.add(this, 'l_p_6_offset', -50, 50, 1).onChange(function() {
            product_manager_module.left_post_offset(5, gui_module.index - 1, gui_module.l_p_6_offset / 100);
        });
        gui_module.left_post_offset_gui.push(_gui);
       

        gui_module.bracket_offset_folder_gui = main_setup_posts_folder_gui.addFolder('Bracket');

       
        const bracketNoKeys = getObjectsKeys(posts_no);
    
        const bracketsNoData = {
            bracket_no: bracketNoKeys[0],
        };      
    
        var bracketsNoGUI = gui_module.bracket_offset_folder_gui.add(bracketsNoData, 'bracket_no', bracketNoKeys).onChange(function() {
            if(bracketsNoData.bracket_no == "automatic") {
                gui_module.forced_brackets_no = 0  
            } else {

                gui_module.forced_brackets_no =   parseInt(posts_no[bracketsNoData.bracket_no]);   
            } 
            product_manager_module.forced_brackets_no(gui_module.index - 1, gui_module.forced_brackets_no)
        });

        bracketsNoGUI.domElement.id = 'bracketsNoGUI';   
        //var _gui;
        _gui = gui_module.bracket_offset_folder_gui.add(this, 'br_1_offset', -50, 50, 1).onChange(function() {
            product_manager_module.bracket_offset(0, gui_module.index - 1, gui_module.br_1_offset / 100);
        });
        gui_module.bracket_offset_gui.push(_gui);
        _gui = gui_module.bracket_offset_folder_gui.add(this, 'br_2_offset', -50, 50, 1).onChange(function() {
            product_manager_module.bracket_offset(1, gui_module.index - 1, gui_module.br_2_offset / 100);
        });
        gui_module.bracket_offset_gui.push(_gui);
        _gui = gui_module.bracket_offset_folder_gui.add(this, 'br_3_offset', -50, 50, 1).onChange(function() {
            product_manager_module.bracket_offset(2, gui_module.index - 1, gui_module.br_3_offset / 100);
        });
        gui_module.bracket_offset_gui.push(_gui);
        _gui = gui_module.bracket_offset_folder_gui.add(this, 'br_4_offset', -50, 50, 1).onChange(function() {
            product_manager_module.bracket_offset(3, gui_module.index - 1, gui_module.br_4_offset / 100);
        });
        gui_module.bracket_offset_gui.push(_gui);
        _gui = gui_module.bracket_offset_folder_gui.add(this, 'br_5_offset', -50, 50, 1).onChange(function() {
            product_manager_module.bracket_offset(4, gui_module.index - 1, gui_module.br_5_offset / 100);
        });
        gui_module.bracket_offset_gui.push(_gui);
        _gui = gui_module.bracket_offset_folder_gui.add(this, 'br_6_offset', -50, 50, 1).onChange(function() {
            product_manager_module.bracket_offset(5, gui_module.index - 1, gui_module.br_6_offset / 100);
        });
        gui_module.bracket_offset_gui.push(_gui);

        _gui = gui_module.bracket_offset_folder_gui.add(this, 'br_1_size', 0, 20, 0.1).onChange(function() {
            product_manager_module.bracket_size(0, gui_module.index - 1, gui_module.br_1_size);
        }); 
        gui_module.bracket_length_gui.push(_gui);
        _gui = gui_module.bracket_offset_folder_gui.add(this, 'br_2_size', 0, 20, 0.1).onChange(function() {
            product_manager_module.bracket_size(1, gui_module.index - 1, gui_module.br_2_size);
        }); 
        gui_module.bracket_length_gui.push(_gui);
        _gui = gui_module.bracket_offset_folder_gui.add(this, 'br_3_size', 0, 20, 0.1).onChange(function() {
            product_manager_module.bracket_size(2, gui_module.index - 1, gui_module.br_3_size);
        }); 
        gui_module.bracket_length_gui.push(_gui);
        _gui = gui_module.bracket_offset_folder_gui.add(this, 'br_4_size', 0, 20, 0.1).onChange(function() {
            product_manager_module.bracket_size(3, gui_module.index - 1, gui_module.br_4_size);
        }); 
        gui_module.bracket_length_gui.push(_gui);
        _gui = gui_module.bracket_offset_folder_gui.add(this, 'br_5_size', 0, 20, 0.1).onChange(function() {
            product_manager_module.bracket_size(4, gui_module.index - 1, gui_module.br_5_size);
        }); 
        gui_module.bracket_length_gui.push(_gui);
        _gui = gui_module.bracket_offset_folder_gui.add(this, 'br_6_size', 0, 20, 0.1).onChange(function() {
            product_manager_module.bracket_size(5, gui_module.index - 1, gui_module.br_6_size);
        }); 
        gui_module.bracket_length_gui.push(_gui);

        gui_module.decking_post_offset_folder_gui = main_setup_posts_folder_gui.addFolder('Decking');
        const deckingPostTypeKeys = getObjectsKeys(postTypes);
  
        const deckingPostTypesData = {
           post_type: deckingPostTypeKeys[0],
        };      
      
        var deckingPostTypeGUI = gui_module.decking_post_offset_folder_gui.add(deckingPostTypesData, 'post_type', deckingPostTypeKeys).onChange(function() {
            if(deckingPostTypesData.post_type == "metal") {
                gui_module.decking_post_width = 0.08;         
            } else if(deckingPostTypesData.post_type == "wide_metal") {                 
                gui_module.decking_post_width = 0.16;        
            } else if(deckingPostTypesData.post_type == "wood") {
                gui_module.decking_post_width = 0.125;         
            } else {
                gui_module.decking_post_width = 0.15;
            } 
            product_manager_module.decking_post_width(gui_module.index - 1, gui_module.decking_post_width)
        });
    
        deckingPostTypeGUI.domElement.id = 'deckingPostTypeGUI';
    
     
        const deckingPostNoKeys = getObjectsKeys(posts_no);
      
        const deckingPostsNoData = {
           post_no: deckingPostNoKeys[0],
        };      
      
        var deckingPostsNoGUI = gui_module.decking_post_offset_folder_gui.add(deckingPostsNoData, 'post_no', deckingPostNoKeys).onChange(function() {
            if(deckingPostsNoData.post_no == "automatic") {
                gui_module.forced_decking_posts_no = 0;   
            } else {
                gui_module.forced_decking_posts_no = parseInt(posts_no[deckingPostsNoData.post_no]);
            } 
            product_manager_module.forced_decking_posts_no(gui_module.index - 1, gui_module.forced_decking_posts_no)  
        });
    
        deckingPostsNoGUI.domElement.id = 'deckingPostsNoGUI';
        //var _gui;
        _gui = gui_module.decking_post_offset_folder_gui.add(this, 'd_p_1_offset', -50, 50, 1).onChange(function() {
            product_manager_module.decking_post_offset(0, gui_module.index - 1, gui_module.d_p_1_offset / 100);
        });
        gui_module.decking_post_offset_gui.push(_gui);
        _gui = gui_module.decking_post_offset_folder_gui.add(this, 'd_p_2_offset', -50, 50, 1).onChange(function() {
            product_manager_module.decking_post_offset(1, gui_module.index - 1, gui_module.d_p_2_offset / 100);
        });
        gui_module.decking_post_offset_gui.push(_gui);
        _gui = gui_module.decking_post_offset_folder_gui.add(this, 'd_p_3_offset', -50, 50, 1).onChange(function() {
            product_manager_module.decking_post_offset(2, gui_module.index - 1, gui_module.d_p_3_offset / 100);
        });
        gui_module.decking_post_offset_gui.push(_gui);
        _gui = gui_module.decking_post_offset_folder_gui.add(this, 'd_p_4_offset', -50, 50, 1).onChange(function() {
            product_manager_module.decking_post_offset(3, gui_module.index - 1, gui_module.d_p_4_offset / 100);
        });
        gui_module.decking_post_offset_gui.push(_gui);
        _gui = gui_module.decking_post_offset_folder_gui.add(this, 'd_p_5_offset', -50, 50, 1).onChange(function() {
            product_manager_module.decking_post_offset(4, gui_module.index - 1, gui_module.d_p_5_offset / 100);
        });
        gui_module.decking_post_offset_gui.push(_gui);
        _gui = gui_module.decking_post_offset_folder_gui.add(this, 'd_p_6_offset', -50, 50, 1).onChange(function() {
            product_manager_module.decking_post_offset(5, gui_module.index - 1, gui_module.d_p_6_offset / 100);
        });
        gui_module.decking_post_offset_gui.push(_gui);

        var main_framing_folder_gui = gui_module.setup_gui.addFolder('Framing Setup')
        gui_module.rafter_offset_folder_gui = main_framing_folder_gui.addFolder('Rafter')
        const rafterNoKeys = getObjectsKeys(posts_no)
      
        const raftersNoData = {
           rafter_no: rafterNoKeys[0],
        };      
      
        var raftersNoGUI = gui_module.rafter_offset_folder_gui.add(raftersNoData, 'rafter_no', rafterNoKeys).onChange(function() {
            if(raftersNoData.rafter_no == "automatic") {
                gui_module.forced_rafters_no = 0   
            } else {
                gui_module.forced_rafters_no = parseInt(posts_no[raftersNoData.rafter_no])
            } 
            product_manager_module.forced_rafters_no(gui_module.index - 1, gui_module.forced_rafters_no)  
        });
    
        raftersNoGUI.domElement.id = 'raftersNoGUI'

        _gui = gui_module.rafter_offset_folder_gui.add(this, 'rf_1_offset', -50, 50, 1).onChange(function() {
            product_manager_module.rafter_offset(0, gui_module.index - 1, gui_module.rf_1_offset / 100);
        });
        gui_module.rafter_offset_gui.push(_gui);
        _gui = gui_module.rafter_offset_folder_gui.add(this, 'rf_2_offset', -50, 50, 1).onChange(function() {
            product_manager_module.rafter_offset(1, gui_module.index - 1, gui_module.rf_2_offset / 100);
        });
        gui_module.rafter_offset_gui.push(_gui);
        _gui = gui_module.rafter_offset_folder_gui.add(this, 'rf_3_offset', -50, 50, 1).onChange(function() {
            product_manager_module.rafter_offset(2, gui_module.index - 1, gui_module.rf_3_offset / 100);
        });
        gui_module.rafter_offset_gui.push(_gui);
        _gui = gui_module.rafter_offset_folder_gui.add(this, 'rf_4_offset', -50, 50, 1).onChange(function() {
            product_manager_module.rafter_offset(3, gui_module.index - 1, gui_module.rf_4_offset / 100);
        });
        gui_module.rafter_offset_gui.push(_gui);
        _gui = gui_module.rafter_offset_folder_gui.add(this, 'rf_5_offset', -50, 50, 1).onChange(function() {
            product_manager_module.rafter_offset(4, gui_module.index - 1, gui_module.rf_5_offset / 100);
        });
        gui_module.rafter_offset_gui.push(_gui);
        _gui = gui_module.rafter_offset_folder_gui.add(this, 'rf_6_offset', -50, 50, 1).onChange(function() {
            product_manager_module.rafter_offset(5, gui_module.index - 1, gui_module.rf_6_offset / 100);
        });
        gui_module.rafter_offset_gui.push(_gui)

        gui_module.purlin_offset_folder_gui = main_framing_folder_gui.addFolder('Purlin')
        const purlinNoKeys = getObjectsKeys(posts_no)
      
        const purlinsNoData = {
           purlin_no: purlinNoKeys[0],
        };      
      
        var purlinsNoGUI = gui_module.purlin_offset_folder_gui.add(purlinsNoData, 'purlin_no', purlinNoKeys).onChange(function() {
            if(purlinsNoData.purlin_no == "automatic") {
                gui_module.forced_purlins_no = 0   
            } else {
                gui_module.forced_purlins_no = parseInt(posts_no[purlinsNoData.purlin_no])
            } 
            product_manager_module.forced_purlins_no(gui_module.index - 1, gui_module.forced_purlins_no)  
        });
    
        purlinsNoGUI.domElement.id = 'purlinsNoGUI'

        _gui = gui_module.purlin_offset_folder_gui.add(this, 'pr_1_offset', -50, 50, 1).onChange(function() {
            product_manager_module.purlin_offset(0, gui_module.index - 1, gui_module.pr_1_offset / 100);
        });
        gui_module.purlin_offset_gui.push(_gui);
        _gui = gui_module.purlin_offset_folder_gui.add(this, 'pr_2_offset', -50, 50, 1).onChange(function() {
            product_manager_module.purlin_offset(1, gui_module.index - 1, gui_module.pr_2_offset / 100);
        });
        gui_module.purlin_offset_gui.push(_gui);
        _gui = gui_module.purlin_offset_folder_gui.add(this, 'pr_3_offset', -50, 50, 1).onChange(function() {
            product_manager_module.purlin_offset(2, gui_module.index - 1, gui_module.pr_3_offset / 100);
        });
        gui_module.purlin_offset_gui.push(_gui);
        _gui = gui_module.purlin_offset_folder_gui.add(this, 'pr_4_offset', -50, 50, 1).onChange(function() {
            product_manager_module.purlin_offset(3, gui_module.index - 1, gui_module.pr_4_offset / 100);
        });
        gui_module.purlin_offset_gui.push(_gui);
        _gui = gui_module.purlin_offset_folder_gui.add(this, 'pr_5_offset', -50, 50, 1).onChange(function() {
            product_manager_module.purlin_offset(4, gui_module.index - 1, gui_module.pr_5_offset / 100);
        });
        gui_module.purlin_offset_gui.push(_gui);
        _gui = gui_module.purlin_offset_folder_gui.add(this, 'pr_6_offset', -50, 50, 1).onChange(function() {
            product_manager_module.purlin_offset(5, gui_module.index - 1, gui_module.pr_6_offset / 100);
        });
        gui_module.purlin_offset_gui.push(_gui);
    },
    saveFile(strData, filename) {
        var link = document.createElement('a');
        if(typeof link.download === 'string') {
            document.body.appendChild(link); //Firefox requires the link to be in the body
            link.download = filename;
            link.href = strData;
            link.click();
            document.body.removeChild(link); //remove the link when done
        } else {
            location.replace(uri);
        }
    },
    addImage(name) {
        var imgData;
        var strDownloadMime = "image/octet-stream";  
        try {
            var strMime = "image/jpeg";
            imgData = sceneItems.renderer.domElement.toDataURL(strMime);    
            gui_module.saveFile(imgData.replace(strMime, strDownloadMime), name + ".jpg")    
        } catch (e) {
            return;
        }
    },
    set_save_image_gui() {
        var saveFileName = {
            Name: "smartkits"
        };
          var saveImage = {
            add: function() {
                gui_module.addImage(saveFileName.Name)
            }
          };
      
          var gridOnOff = {
            toggle: function() {     
              sceneItems.grid.visible = gridOnOff.grid;        
            },
            grid: false
          }; 
      
          var outputFolder = gui_module.gui.addFolder('Output');
      
          outputFolder.add(gridOnOff, "grid").onChange(gridOnOff.toggle);
          gridOnOff.toggle();
      
          outputFolder.add(saveFileName, "Name").onFinishChange(function (value) {
            saveFileName.Name = value;
          });
      
          outputFolder.add(saveImage, "add").name("Save Image");
          gui_module.set_3d_text(outputFolder);
    },
    title_font: null,    
    indicator: true,
    indicator_gui: null,
    create_text_mesh: function(mesh, _size, text) {
        if(mesh) sceneItems.scene.remove(mesh)
        return setupTextGeometry(text, _size)
    },
    set_3d_text: function(f) {
        var mesh
        var text3D = {
            Upper: '', //'Smarter',
            Middle: '', //'Smarter',
            Lower: '', //'Not Harder',
            Color: 0xbcd8d8,
            Rotation: 0,
            Height: 2,
            Depth: 0,
            X: -5,
            Size: 1
        }

        gui_module.indicator_gui = f.add(gui_module, "indicator").onChange(function() { 
            text_scene()
        }); 
        var folder = f.addFolder('Title');
        folder.add(text3D, "Upper").onFinishChange(function (value) {
            text3D.Upper = value;
            text_scene()
        })
        folder.add(text3D, "Middle").onFinishChange(function (value) {
            text3D.Middle = value;
            text_scene()
        })
        folder.add(text3D, "Lower").onFinishChange(function (value) {
            text3D.Lower = value;
            text_scene()
        })         
        folder.add(text3D, 'Size', 0.1, 5, 0.1).onChange(function() {
            text_scene()
        })
        folder.add(text3D, 'Height', 0, 32, 0.1).onChange(function() {
            text_scene()
        })
        folder.add(text3D, 'Depth', -30, 30, 0.1).onChange(function() {
            text_scene()
        })
        folder.add(text3D, 'X', -30, 30, 0.1).onChange(function() {
            text_scene()
        }) 
        folder.add(text3D, 'Rotation', 0, 360, 1).onChange(function() {
            text_scene()
        })
        folder.addColor(text3D, 'Color').onChange(function() { 
            text_scene()
        });

        setupFont(text_scene)
        function text_scene() {            
            const textGeo = gui_module.create_text_mesh(mesh, text3D.Size, text3D.Upper + '\n' + text3D.Middle + '\n' + text3D.Lower)
            const textMaterial = new THREE.MeshPhongMaterial({ color: text3D.Color, specular: 0x000000 })

            mesh = new THREE.Mesh(textGeo, textMaterial)
            mesh.position.x = text3D.X
            mesh.position.y = sceneItems.scene.position.y + text3D.Height
            mesh.position.z = text3D.Depth            
            mesh.rotation.y = text3D.Rotation * Math.PI / 180
            mesh.castShadow = false
            mesh.receiveShadow = false
            sceneItems.scene.add(mesh)
            gui_module.set_dim_text()
        }
    },
    len_mesh: null, 
    pro_mesh: null,
    ar1_mesh: null,
    an1_mesh: null,
    ar2_mesh: null,
    an2_mesh: null,
    ar3_mesh: null,
    an3_mesh: null,
    ar4_mesh: null,
    an4_mesh: null,
    front_mesh: null, 
    ar5_mesh: null,
    an5_mesh: null,
    side_mesh: null, 
    ar6_mesh: null,
    an6_mesh: null,
    v1: new THREE.Vector3(),
    v2: new THREE.Vector3(),
    set_dim_text: function() { 
        //return
        var dim3D = {
            bar: null,            
            Color: 0xffff00,
            Rotation: 0,
            Height: 2,
            Depth: 0,
            X: -5,
            Size: 0.75
        }        
        
        var product = product_manager_module.products[gui_module.index - 1];
        if(gui_module.indicator && product && product.type != 29 && product.type != 32 && product.type != 33) {            
            check_meshes(true)
            dim_scene()
        } else {
            check_meshes(false)
        }

        function check_meshes(v) {
            if(gui_module.an1_mesh) gui_module.an1_mesh.visible = v 
            if(gui_module.ar1_mesh) gui_module.ar1_mesh.visible = v 
            if(gui_module.an2_mesh) gui_module.an2_mesh.visible = v 
            if(gui_module.ar2_mesh) gui_module.ar2_mesh.visible = v 
            if(gui_module.an3_mesh) gui_module.an3_mesh.visible = v 
            if(gui_module.ar3_mesh) gui_module.ar3_mesh.visible = v 
            if(gui_module.an4_mesh) gui_module.an4_mesh.visible = v 
            if(gui_module.ar4_mesh) gui_module.ar4_mesh.visible = v 
            if(gui_module.len_mesh) gui_module.len_mesh.visible = v 
            if(gui_module.pro_mesh) gui_module.pro_mesh.visible = v 
            if(gui_module.an5_mesh) gui_module.an5_mesh.visible = v 
            if(gui_module.ar5_mesh) gui_module.ar5_mesh.visible = v 
            if(gui_module.front_mesh) gui_module.front_mesh.visible = v 
            if(gui_module.an6_mesh) gui_module.an6_mesh.visible = v 
            if(gui_module.ar6_mesh) gui_module.ar6_mesh.visible = v 
            if(gui_module.side_mesh) gui_module.side_mesh.visible = v 
        }
        
        function dim_scene() {
           
            const textMaterial = new THREE.MeshPhongMaterial({ color: dim3D.Color, specular: 0x000000 })

            var angle = (product.rotate + 270) * Math.PI / 180

            function setup_obj(obj, s, r1, r2, v1, v2, h) {
                obj.scale.x = s
                obj.rotation.set(r1, 0, r2 + angle)
                obj.position.copy(product.position) 
                gui_module.v1.copy(product.front_2_end_unit_vector)
                gui_module.v2.copy(product.left_2_right_unit_vector)
                gui_module.v1.multiplyScalar(v1)
                gui_module.v2.multiplyScalar(v2)
                gui_module.v2.add(gui_module.v1)
                obj.position.add(gui_module.v2)    
                obj.position.y = h 
            }
            var val1 = product.length
            if(product.type == 28) {
                val1 = product.projection
            }
            var scale1 = 1
            var offset1 = 0
            var offset2 = 1.7
            var offset3 = 0
            if(product.type < 5)  offset3 = -1 * product.front_overhang * product.projection / 2
            else if(product.type == 100) offset3 = product.projection + 3 + product.front_overhang
            else if(product.type > 63) offset3 = -1 * product.front_overhang
            if(val1 < 4) {
                scale1 = 0.6
                offset1 = 0.1
                offset2 = 1
            } else if(val1 > 9.9) {
                offset2 = 2
            }

            if(!gui_module.an1_mesh) {                      
                gui_module.an1_mesh = new THREE.Mesh(gui_module.create_text_mesh(gui_module.an1_mesh, 1, '<'), textMaterial)                  
                sceneItems.scene.add(gui_module.an1_mesh)
            }
            setup_obj(gui_module.an1_mesh, 0.5, 3 * Math.PI / 2, 0, -2 + offset3, 0, 0)

            if(!gui_module.ar1_mesh) {                      
                gui_module.ar1_mesh = new THREE.Mesh(new THREE.BoxGeometry(1, 0.12, 0.12), textMaterial)    
                sceneItems.scene.add(gui_module.ar1_mesh)
            }
            setup_obj(gui_module.ar1_mesh, val1 / 2.8 - 1.0, 3 * Math.PI / 2, 0, -1.6 + offset3, val1 / 5.6 - 0.3, 0.06)

            if(gui_module.len_mesh) sceneItems.scene.remove(gui_module.len_mesh)                                   
            gui_module.len_mesh = new THREE.Mesh(gui_module.create_text_mesh(gui_module.len_mesh, scale1, ' ' + val1?.toFixed(2) + ' '), textMaterial);
            sceneItems.scene.add(gui_module.len_mesh)
            setup_obj(gui_module.len_mesh, 1, 3 * Math.PI / 2, 0, -2 + offset1 + offset3, val1 / 2 - offset2, 0)

            if(!gui_module.ar2_mesh) {                      
                gui_module.ar2_mesh = new THREE.Mesh(new THREE.BoxGeometry(1, 0.12, 0.12), textMaterial)    
                sceneItems.scene.add(gui_module.ar2_mesh)
            }
            setup_obj(gui_module.ar2_mesh, val1 / 2.8 - 1, 3 * Math.PI / 2, 0, -1.6 + offset3, val1 - val1 / 5.6 + 0.2, 0.06)

            if(!gui_module.an2_mesh) {                      
                gui_module.an2_mesh = new THREE.Mesh(gui_module.create_text_mesh(gui_module.an2_mesh, 1, '>'), textMaterial)                  
                sceneItems.scene.add(gui_module.an2_mesh)
            }
            setup_obj(gui_module.an2_mesh, 0.5, 3 * Math.PI / 2, 0, -2 + offset3, val1 - 0.5, 0)

            if(product.type == 100) val1 = product.front_overhang
            else val1 = -1 * offset3
            if(!gui_module.an5_mesh) {                      
                gui_module.an5_mesh = new THREE.Mesh(gui_module.create_text_mesh(gui_module.an5_mesh, 1, '>'), textMaterial)                  
                sceneItems.scene.add(gui_module.an5_mesh)
            }
            if(!gui_module.ar5_mesh) {                      
                gui_module.ar5_mesh = new THREE.Mesh(new THREE.BoxGeometry(1, 0.06, 0.06), textMaterial)    
                sceneItems.scene.add(gui_module.ar5_mesh)
            }            
            if(gui_module.front_mesh) sceneItems.scene.remove(gui_module.front_mesh)                                   
            gui_module.front_mesh = new THREE.Mesh(gui_module.create_text_mesh(gui_module.front_mesh, 0.6, ' ' + val1?.toFixed(2) + ' '), textMaterial);
            sceneItems.scene.add(gui_module.front_mesh)
           
            if(val1 < 0.2) {
                gui_module.an5_mesh.visible = false
                gui_module.ar5_mesh.visible = false
                gui_module.front_mesh.visible = false
            } else {
                setup_obj(gui_module.an5_mesh, 0.2, 3 * Math.PI / 2, 3 * Math.PI / 2, -1 * val1, -0.3, 0)
                setup_obj(gui_module.ar5_mesh, val1, 3 * Math.PI / 2, 3 * Math.PI / 2, -1 * val1 / 2, 0.1, 0.03)
                setup_obj(gui_module.front_mesh, 0.6, 3 * Math.PI / 2, 3 * Math.PI / 2, -1 * val1, 0.7, 0)
            }

            var val2 = product.projection
            if(product.type == 28) {
                val2 = product.length
            }

            scale1 = 1
            offset1 = 0
            offset2 = 1.7
            offset3 = 0
            if(product.type > 63) offset3 = -1 * product.back_overhang
            var offset4 = 0
            if(product.type < 5) offset4 = -1 * product.front_overhang * product.projection / 2
            if(val2 < 4) {
                scale1 = 0.6
                offset1 = 0.15
                offset2 = 1
            } else if(val2 > 9.9) {
                offset2 = 2
            }

            if(!gui_module.an3_mesh) {                      
                gui_module.an3_mesh = new THREE.Mesh(gui_module.create_text_mesh(gui_module.an3_mesh, 1, '<'), textMaterial)                  
                sceneItems.scene.add(gui_module.an3_mesh)
            }
            setup_obj(gui_module.an3_mesh, 0.5, 3 * Math.PI / 2 , 3 * Math.PI / 2, val2 + offset4, -2 + offset3, 0)

            if(!gui_module.ar3_mesh) {                      
                gui_module.ar3_mesh = new THREE.Mesh(new THREE.BoxGeometry(1, 0.12, 0.12), textMaterial)    
                sceneItems.scene.add(gui_module.ar3_mesh)
            }

            setup_obj(gui_module.ar3_mesh, val2 / 2.8 - 1, 3 * Math.PI / 2 , 3 * Math.PI / 2, val2 - val2 / 5.6 + 0.4  + offset4, -1.6 + offset3, 0.06)

            if(gui_module.pro_mesh) sceneItems.scene.remove(gui_module.pro_mesh)                                   
            gui_module.pro_mesh = new THREE.Mesh(gui_module.create_text_mesh(gui_module.pro_mesh, scale1, ' ' + val2?.toFixed(2) + ' '), textMaterial);
            sceneItems.scene.add(gui_module.pro_mesh)
            setup_obj(gui_module.pro_mesh, 1, 3 * Math.PI / 2 , 3 * Math.PI / 2, val2 / 2 + offset2 + offset4, -2 + offset1 + offset3, 0)

             if(!gui_module.ar4_mesh) {                      
                gui_module.ar4_mesh = new THREE.Mesh(new THREE.BoxGeometry(1, 0.12, 0.12), textMaterial)    
                sceneItems.scene.add(gui_module.ar4_mesh)
            }

            setup_obj(gui_module.ar4_mesh, val2 / 2.8 - 1, 3 * Math.PI / 2 , 3 * Math.PI / 2, val2 / 5.6 - 0.4 + offset4, -1.6 + offset3, 0.06)

            if(!gui_module.an4_mesh) {                      
                gui_module.an4_mesh = new THREE.Mesh(gui_module.create_text_mesh(gui_module.an4_mesh, 1, '>'), textMaterial)                  
                sceneItems.scene.add(gui_module.an4_mesh)
            }
            setup_obj(gui_module.an4_mesh, 0.5, 3 * Math.PI / 2 , 3 * Math.PI / 2, 0.4 + offset4, -2 + offset3, 0)

            val1 = -1 * offset3
            if(!gui_module.an6_mesh) {                      
                gui_module.an6_mesh = new THREE.Mesh(gui_module.create_text_mesh(gui_module.an6_mesh, 1, '<'), textMaterial)                  
                sceneItems.scene.add(gui_module.an6_mesh)
            }
            if(!gui_module.ar6_mesh) {                      
                gui_module.ar6_mesh = new THREE.Mesh(new THREE.BoxGeometry(1, 0.06, 0.06), textMaterial)    
                sceneItems.scene.add(gui_module.ar6_mesh)
            }            
            if(gui_module.side_mesh) sceneItems.scene.remove(gui_module.side_mesh)                                   
            gui_module.side_mesh = new THREE.Mesh(gui_module.create_text_mesh(gui_module.side_mesh, 0.6, ' ' + val1?.toFixed(2) + ' '), textMaterial);
            sceneItems.scene.add(gui_module.side_mesh)
           
            if(val1 < 0.2) {
                gui_module.an6_mesh.visible = false
                gui_module.ar6_mesh.visible = false
                gui_module.side_mesh.visible = false
            } else {
                setup_obj(gui_module.an6_mesh, 0.2, 3 * Math.PI / 2, 0, -0.1, -1 * val1 - 0.1, 0)
                setup_obj(gui_module.ar6_mesh, val1, 3 * Math.PI / 2, 0, 0.3, -1 * val1 / 2, 0.03)
                setup_obj(gui_module.side_mesh, 0.6, 3 * Math.PI / 2, 0, 0.8, -1 * val1 - 1.0, 0)
            }
        }
    },
    set_accessories_gui: function() {
        var accessories = gui_module.gui.addFolder('Accessories');

        var accessoriesFunctions = {
            size: true,
            gallery: false,
            frame: 1,
            visibleGallery: function() {
                
                this.gallery = !this.gallery;
    
                if(this.gallery) {
                    if(gui_module.logo) {
                        gui_module.logo.visible = false;
                    }
                    for(var i = 0; i < product_manager_module.products.length; ++i) {
                        product_manager_module.products[i].scene_group.visible = false
                    } 
                    hideElement(galleryAttributes.domElement, false)
                    product_manager_module.show_gallery(gui_module.index - 1, 1)

                    sceneItems.grid.visible = false;   
                    setPanelVisible(false)
                    setVisibleDecModels(false);
                    setPriceDialog(true)
                    sun.visible = false;
                } else {
                    if(gui_module.logo) {
                        gui_module.logo.visible = true;
                    }
                    for(var i = 0; i < product_manager_module.products.length; ++i) {
                        product_manager_module.products[i].scene_group.visible = true
                    }
                    hideElement(galleryAttributes.domElement, true)
                    sceneItems.scene.background = null;
                    setPanelVisible(true)
                    setVisibleDecModels(true);
                    if(gui_module.size) setPriceDialog(false)
                    sun.visible = sunOnOff.sun;
                }
            }, 
            model_scale: 1,
            model_x: 1,
            model_y: 1,
            model_z: 1,
            loadAccessory: function() {
                document.getElementById('loadAccessoryInput').click();
            },      
            setScale: function() {
                if(glbObject) {
                    glbObject.traverse( function( object ) {
                        if(object.isMesh) object.scale.set(accessoriesFunctions.model_scale, accessoriesFunctions.model_scale, accessoriesFunctions.model_scale);
                    } );         
                }
            },
            lock: false
        };

        
        addAccessoriesGUI(accessories, null);
        var extraAccessoriesGUI = accessories.addFolder('Extra');    
     
        var license = {
            credits: false,
            show: function() {
                if(license.credits) { 
                    setDialogVisible(true)
                    setPriceDialog(true)
                } else {
                    setDialogVisible(false)
                    if(gui_module.size)  setPriceDialog(false)
                } 
            }
        }

        extraAccessoriesGUI.add(license, "credits").onChange(license.show);
        const creditInput = document.getElementById("creditInput");

        accessories.add(gui_module, "global_lock").onChange(function() {});
    },
    set_landscaping_gui(accessories) {
        var landscapingFunctions = {
            visible: false,
            color: '#F70000',
            lock: true,
            rotation: 0,
            number: 640,
            x_offset: 0,
            z_offset: 0,
            decking_height: 0.4,
            loadLandscapingImageInput1Call: function () {
                document.getElementById('loadLandscapingImageInput1').click();
            },
            loadLandscapingImageInput2Call: function () {
                document.getElementById('loadLandscapingImageInput2').click();
            },
            loadLandscapingImageInput3Call: function () {
                document.getElementById('loadLandscapingImageInput3').click();
            },
            loadLandscapingImageInput4Call: function () {
                document.getElementById('loadLandscapingImageInput4').click();
            },
            addLandscaping: function () {
            }
        }

        const landscapingModels = (function () {
            return {
                pavement: "Pavement",
                decking: "Decking",
                concrete: "Concrete",
                tile: "Tile",
                stone: "Stone",
                custom: "Custom"
            };
        })();
        
        const landscapingModeleKeys = getObjectsKeys(landscapingModels);
        
        const landscapingModelsData = {
            type: landscapingModeleKeys[0],
        };

        var landscapingAttributes = accessories.addFolder("Landscaping");
        gui_module.landscaping_visible_gui = landscapingAttributes.add(landscapingFunctions, "visible").onChange(function () {
            product_manager_module.set_landscaping_visible(gui_module.index - 1, landscapingFunctions.visible)
        });
    
        /*gui_module.landscaping_height_gui = landscapingAttributes.add(landscapingFunctions, 'decking_height', 0, 4, 0.1).onChange(function () {
            product_manager_module.set_landscaping_height(gui_module.index - 1, landscapingFunctions.decking_height)
        });*/
    
        gui_module.landscaping_x_offset_gui = landscapingAttributes.add(landscapingFunctions, 'x_offset', -0.2, 3, 0.1).onChange(function () {
            product_manager_module.set_landscaping_x_offset(gui_module.index - 1, landscapingFunctions.x_offset)
        });
        gui_module.landscaping_z_offset_gui = landscapingAttributes.add(landscapingFunctions, 'z_offset', -0.2, 3, 0.1).onChange(function () {
            product_manager_module.set_landscaping_z_offset(gui_module.index - 1, landscapingFunctions.z_offset)
        });
        
        gui_module.landscaping_type_gui = landscapingAttributes.add(landscapingModelsData, 'type', landscapingModeleKeys).onChange(function () {
            product_manager_module.set_landscaping_type(gui_module.index - 1, landscapingModelsData.type)
        });
    
        landscapingAttributes.add(landscapingFunctions, 'loadLandscapingImageInput1Call').name('Load Custom');
        const loadLandscapingImageInput1 = document.getElementById("loadLandscapingImageInput1");
        if(loadLandscapingImageInput1) loadLandscapingImageInput1.addEventListener("change", function() {
            var file = document.getElementById('loadLandscapingImageInput1').files[0];
            document.getElementById('loadLandscapingImageInput1').value = ''
            var custom_material = loadLandscapingImageInputFunction(file);
            product_manager_module.set_landscaping_custom_material(gui_module.index - 1, custom_material)
            function loadLandscapingImageInputFunction(file) {
                var filePath = (window.URL || window.webkitURL).createObjectURL(file);
                return loadLandscapingImageInputFunctionFromPath(filePath);
            }
            
            function loadLandscapingImageInputFunctionFromPath(filePath) {
                var loader = new THREE.TextureLoader()
                return new THREE.MeshStandardMaterial({
                    map: loader.load(filePath)
                });
            }

        }, false);
    
        gui_module.landscaping_type_gui.domElement.id = 'landscapingModelGUI';
    },
    set_scene_gui() {        
        var sceneFolder = gui_module.gui.addFolder('Scene');
        var sceneRotationMatrix = {
            x: 0,
            y: 0,
            z: 0
          };          

        function sceneRotationChanged() {
            sceneItems.scene.rotation.x = sceneRotationMatrix['x'] * Math.PI / 180;
            sceneItems.scene.rotation.y = sceneRotationMatrix['y'] * Math.PI / 180;
            sceneItems.scene.rotation.z = sceneRotationMatrix['z'] * Math.PI / 180;
        }    
        var sceneRotationFolder = sceneFolder.addFolder('Rotation');
        var sceneRotationX = sceneRotationFolder.add(sceneRotationMatrix, 'x', -45, 45, 0.01).onChange(sceneRotationChanged);
        var sceneRotationY = sceneRotationFolder.add(sceneRotationMatrix, 'y', -45, 45, 0.01).onChange(sceneRotationChanged);
        var sceneRotationZ = sceneRotationFolder.add(sceneRotationMatrix, 'z', -45, 45, 0.01).onChange(sceneRotationChanged);
       
        var scenePositionMatrix = {
            x: 0,
            y: 0,
            z: -6
        }         
        function scenePostionChanged() {
            sceneItems.scene.position.x = scenePositionMatrix['x'];
            sceneItems.scene.position.y = scenePositionMatrix['y'];
            sceneItems.scene.position.z = scenePositionMatrix['z'];
        }

        var scenePositionFolder = sceneFolder.addFolder('Position');
        var scenePositionX = scenePositionFolder.add(scenePositionMatrix, 'x', -25, 25, 0.1).onChange(scenePostionChanged);
        var scenePositionY = scenePositionFolder.add(scenePositionMatrix, 'y', -25, 25, 0.1).onChange(scenePostionChanged);
        var scenePositionZ = scenePositionFolder.add(scenePositionMatrix, 'z', -25, 25, 0.1).onChange(scenePostionChanged);
        
        var  sceneFunctions = {
            reset: function() {
                sceneRotationX.setValue(0);
                sceneRotationY.setValue(0);
                sceneRotationZ.setValue(0);
                scenePositionX.setValue(0);
                scenePositionY.setValue(0);
                scenePositionZ.setValue(0);
                sceneItems.controler.reset();
            }
        };
        sceneFolder.add(sceneFunctions, "reset").name("Reset");

        function updateCamera() {
            sceneItems.camera.updateProjectionMatrix();
        } 

        const fov = 1;
        //const aspect = 2;  // the canvas default
        const near = 0.1; 
        const far = 1000;
        var cameraFolder = gui_module.gui.addFolder('Camera');
        cameraFolder.add(sceneItems.camera, 'fov', 1, 180).onChange(updateCamera);
        cameraFolder.add(sceneItems.camera, 'near', 0.1, 50, 0.1).onChange(updateCamera);
        cameraFolder.add(sceneItems.camera, 'far', 0.1, 50, 0.1).onChange(updateCamera);
        
    },
    set_light_gui() {
        //////////////////////////////////////////////////////////////////////////////   
        var lightFolder = gui_module.gui.addFolder('Sun & Light'); 

        const geometry = new THREE.CircleGeometry(2, 32);
        const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
        sun = new THREE.Mesh(geometry, material);


        lightFolder.add(sunOnOff, "sun").onChange(sunOnOff.toggle);
        sunOnOff.toggle();
        //sun.rotation.x = 45;
        sceneItems.scene.add(sun);
    
        const time_map = ( function () {
        return {
            sunrise: "Sunrise",
            morning: "Morning",
            midday: "Midday",
            afternoon: "Afternoon",
            sunset: "Sunset",
            night: "Night"
        };
        })();

        const timeMapKeys = getObjectsKeys(time_map);

        const timeMapsData = {
            time_map: timeMapKeys[2],
        };
        
        var lightMatrix = {
            x: 1,
            y: 1,
            z: -3,
            travel: 0,
            intensity: 0.6,
            color: 0xffffff,
            ambient: config.home ? 1.8 : 0
          }
        
          var lightMatrix_2nd = {
            x: 0,
            y: -3,
            z: 6,
            travel: 0,
            intensity: 0.8,
            color: 0xffffff,
          }
        
        function ambientChanged() {
            sceneItems.ambient.intensity = lightMatrix.ambient;
        }

        var lightDroplistGUI = lightFolder.add(timeMapsData, 'time_map', timeMapKeys).onChange(changeSunLocation);
        var ambientGUI = lightFolder.add(lightMatrix, 'ambient', 0, 10, 0.1).onChange(ambientChanged);
        function lightChanged() {
            var r = 150
            var x = -1 * r * Math.sin(lightMatrix.travel * Math.PI / 180);
            var y = r * Math.cos(lightMatrix.travel * Math.PI / 180);
            sceneItems.light.position.set(x, y, lightMatrix['z']);
            sceneItems.light.intensity = lightMatrix['intensity']; 
            sceneItems.light_2nd.intensity = lightMatrix_2nd['intensity'];
            
            sun.position.set(x / 2, y / 8, -40);

            sceneItems.light_2nd.position.set(lightMatrix_2nd['x'], lightMatrix_2nd['y'], lightMatrix_2nd['z']);
        }
        var lightIntensityGUI = lightFolder.add(lightMatrix, 'intensity', 0, 3, 0.1).onChange(lightChanged);

        var lightAngleGUI = lightFolder.add(lightMatrix, 'travel', -90, 90, 1).onChange(lightChanged);

        var light2ndFolder = lightFolder.addFolder('2nd Light');  
        light2ndFolder.add(lightMatrix_2nd, 'intensity', 0, 3, 0.1).onChange(lightChanged);
        light2ndFolder.add(lightMatrix_2nd, 'x', -40, 40, 1).onChange(lightChanged);
        light2ndFolder.add(lightMatrix_2nd, 'y', -40, 40, 1).onChange(lightChanged);
        light2ndFolder.add(lightMatrix_2nd, 'z', -40, 40, 1).onChange(lightChanged);   

        lightDroplistGUI.domElement.id = 'lightDroplist';
        function changeSunLocation() {
            lightMatrix.intensity = 0.8;
            if(timeMapsData.time_map == "sunrise") {
                lightMatrix.travel = -80;
            } else if(timeMapsData.time_map == "morning") {
                lightMatrix.travel = -70;
                lightMatrix.intensity = 1.0;
            } else if(timeMapsData.time_map == "midday") {
                lightMatrix.travel= 0;
                lightMatrix.intensity = 1.5;
            } else if(timeMapsData.time_map == "afternoon") {
                lightMatrix.travel = 70;
                lightMatrix.intensity = 1.0;
            } else if(timeMapsData.time_map == "sunset") {
                lightMatrix.travel = 80;
            } else {
                lightMatrix.travel = 90;
                lightMatrix.intensity = 0;
            } 
            lightAngleGUI.setValue(lightMatrix.travel); 
            lightIntensityGUI.setValue(lightMatrix.intensity); 
            ambientGUI.setValue(lightMatrix.ambient);
        }

        changeSunLocation();   
    },
    size: true,
    gui_closed_flag: false,
    periodic_event: function() {        
        if(gui_module.gui.closed && !gui_module.gui_closed_flag) {
            forceSideBarVisible(true);
            setPriceDialog(true);   
            gui_module.gui_closed_flag = true
        }
        if(!gui_module.gui.closed && gui_module.gui_closed_flag) {            
            forceSideBarVisible(false);            
            if(gui_module.size)  setPriceDialog(false)
            gui_module.gui_closed_flag = false;
        }
    }
}

var sun

var sunOnOff = {
    toggle: function() {     
        sun.visible = sunOnOff.sun;        
    },
    sun: false
}; 


export { gui_module, sceneItems, project_specification }


/*handleAccessoryFiles: function() {
    var f = document.getElementById('loadAccessoryInput').files[0];
    document.getElementById('loadAccessoryInput').value = ''
    var p = (window.URL || window.webkitURL).createObjectURL(f);
    addGLTF(p, '../product/js/libs/draco/gltf/', function(gltf) {
        glbObject = gltf.scene;              
        sceneItems.scene.add(glbObject);
        glbObject.traverse( function( object ) {
            if(object.isMesh) object.scale.set(.02, .02, .02);
        } );
    });
},*/
  