/*import * as THREE from '../build/three.module.js';
import { GUI } from '../product/jsm/libs/dat.gui.module.js';
import { OBJLoader } from '../product/jsm/loaders/OBJLoader.js';
import { GLTFLoader } from '../product/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from '../product/jsm/loaders/DRACOLoader.js';
import { FBXLoader } from '../product/jsm/loaders/FBXLoader.js';
import { RGBELoader } from '../product/jsm/loaders/RGBELoader.js';
import { OrbitControls } from '../product/jsm/controls/OrbitControls.js';*/
import * as THREE from 'three';
import { GUI } from 'dat.gui';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';

var no_react = false

import { product_manager_module } from './product_manager_module.js';
import { gui_module, sceneItems } from './gui_smartkits.js'

var pricing = {
    base_price: 5269, //4790,
    unit_price: 266, //242,
    base_ratio: [1,   1, 0.6555, 0.7788, 0.4562, 0.8746, 0.5706, 1.3190, 1.4442, 1.0429,  0.8476, 1.2027, 0.9435, 1.3923, 1.5175, 1.4731, 1.8079, 0.5607, 0.9929, 0.5898, 1.2798, 1.2727, 1.2850, 1.1744, 1.1, 1.2, 1, 1, 1, 0.9929],
    unit_ratio: [1,   1, 0.6900, 0.9100, 0.5300, 1.0000, 0.5400, 0.7200, 0.7500, 1.2500,  0.7600, 1.2400, 0.8500, 0.8600, 0.9200, 0.4600, 0.7000, 0.9400, 1.2500, 0.6000, 0.9400, 0.6500, 0.7600, 0.6700, 1.1, 1.2, 1, 1, 1, 0.2500]
}

function getSize(price_index, lenght, projection, size) {
  var base_size = 9
  //if(price_index == 7 || price_index == 8 || price_index == 13 || price_index == 14 || price_index > 18) base_size = 18
  //console.log(price_index + " -> base: " + pricing.base_ratio[price_index] + " , uni: " + pricing.unit_ratio[price_index] + " , base price: " + pricing.base_price +  " , unit price " + pricing.unit_price)
  return pricing.base_price * pricing.base_ratio[price_index] + (size - base_size) * pricing.unit_price * pricing.unit_ratio[price_index]
}

function getMaterial(color, type) {
  
  return new THREE.MeshPhysicalMaterial( {
      color: color, metalness: 0.9, roughness: 0.8, clearcoat: 0.9, clearcoatRoughness: 0.9
  } );
}

var counter = 0;

function addObj(modelBuffer, model, no, group, call) {
  const loader = new OBJLoader();
  loader.load( model, function (object) {
    for(var i = 0; i < no; ++i) {
      var obj = object.clone()
      modelBuffer.push(obj);
      group.add(obj);
      obj.visible = false;
    }
    call();
  });
}

function addGLTF(model, draco, call) {
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath(draco);
  const loader = new GLTFLoader();
  loader.setDRACOLoader( dracoLoader );
  loader.load(model, function (gltf) {
      call(gltf);
  } );
}

function loadImage(file, call) {
  const loader = new THREE.TextureLoader();
  loader.load(
      // resource URL
      file,
      // onLoad callback
      function(img) {
        call(img)
      }     
  );
}

function loadMaterial(file) {
  var loader = new THREE.TextureLoader()
  return new THREE.MeshStandardMaterial({
      map:loader.load(file)
  });
}

function keepObjectInsideScene(obj) {
  if(obj.z > 40) obj.z = 40;
  if(obj.z < -40) obj.z = -40;
  if(obj.x > 40) obj.x = 40;
  if(obj.x < -40) obj.x = -40;
  if(obj.y > 40) obj.y = 40;
  if(obj.y < -40) obj.y = -40;
}

function getObjectsKeys( obj ) {
  const keys = [];

  for ( const key in obj ) {
    if ( obj.hasOwnProperty( key ) ) {
      keys.push( key );
    }
  }
  return keys;
}

function getNewAccessoryObjectHolder(name) {
  var obj = { "type": name, "parts": [], "height": 0, "lock": false, "rotate": 0 };
  return obj;
}


function generatePriceTable() {
    var rowData = []
    var total = 0
    var productNo = 0
    for(var i = 0; i < product_manager_module.products.length; ++i) {
      var price_index_ = product_manager_module.products[i].price_index
      var len_ = product_manager_module.products[i].length
      var projection_ = product_manager_module.products[i].projection
      var step_length_ = product_manager_module.products[i].step_length
      var step_size_ = product_manager_module.products[i].step_size

      var type_ = product_manager_module.products[i].type
      
      var size_
      if(step_length_ == 0) {
        if(type_ == 13 || type_ == 14) {
          size_ =  ((len_  +  product_manager_module.products[i].back_length) * projection_ / 2).toFixed(2);
        } else size_ =  (len_ * projection_).toFixed(2);
      } else {          
        size_ = ((len_ - step_length_) * projection_ + (step_length_) * step_size_).toFixed(2)
      }
      
      var price_ = getSize(price_index_, len_, projection_, size_)

      if(type_ < 32) {
        productNo++
          var type = "?"
          switch(price_index_) {
              case 1: 
                  type = "Insulated Flyover"
                  break;
              case 2:
                  type = "Non Insulated Flyover"
                  break;
              case 3:
                  type = "Insulated Patio Attached"
                  break;
              case 4:
                  type = "Non Insulated Patio Attached"
                break;    
              case 5:
                  type = "Insulated Patio Freestanding"
                  break;
              case 6:
                  type = "Non Insulated Patio Freestanding"
                break;  
              case 7:
                  type = "Hip Freestanding"
                break; 
              case 8:
                  type = "Hip Attached"
                break;
              case 9:
                  type = "Insulated Gable Freestanding"
                  break;
              case 10:
                  type = "Non Insulated Gable Freestanding"
                break;                  
              case 11:
                  type = "Insulated Gable Attached"
                  break;
              case 12:
                  type = "Non Insulated Gable Attached"
                break;    
              case 13:
                  type = "Dutch Freestanding"
                break; 
              case 14:
                  type = "Dutch Attached"
                break;    
              case 15:
                  type = "Gazebo 6 Sided"
                break; 
              case 16:
                  type = "Gazebo 8 Sided"
                break;  
              case 17:
                  type = "Ground Level Decking"
                break;   
              case 18:
                  type = "High Set Decking"
              break; 
              case 19:
                  type = "Patio Carport"
              break;  
              case 20:
                  type = "Dutch Carport"
              break;   
              case 21:
                  type = "Gable Carport"
              break;  
              case 22:
                  type = "Hip Carport"
              break;     
              case 23:
                type = "Caravan Boatport"
              break; 
              case 24:
                type = "Angled Attached"
              break; 
              case 25:
                type = "Angled Freestanding"
              break; 
              case 29: 
                type = "Landing"
              break;
          }
          rowData.push({model: type, price: '$' + price_.toFixed(0), size: size_ })
          total += price_
      }
    }           
    
    return {
      row: rowData,
      total: total }     
}

function updatePrice() {
        
  var columnDefs = [
      { headerName: "Model", field: "model" },
      { headerName: "Price", field: "price" },
      { headerName: "Size", field: "size" }
    ];
      
    var g = generatePriceTable()
    var rowData = g.row
    var total = g.total

    var gridOptions = {
      columnDefs: columnDefs,
      rowData: rowData
    };
    
    var gridDiv = document.querySelector('#myGrid');
    
    if(gridDiv) {
      gridDiv.innerHTML = ""
      new agGrid.Grid(gridDiv, gridOptions)
    }

    var total_price = document.querySelector('#totalPrice')
    if(total_price) total_price.innerText = "Total Price: $" + total.toFixed(2)

    hideElement(document.getElementById('totalPrice'), !gui_module.Price)
    hideElement(document.getElementById('myGrid'), !gui_module.Price)
    if(gridDiv) {                
        gridDiv.style.height = (150 + 40 * productNo) + "px"
    }    
    return {
      row: rowData,
      total: total }     
}

function getAssetsAddress(file) {
  if(findNoReactFlag()) return '../product/' + file
  else return file
}

function colorSetType(type) {
  if(findNoReactFlag()) {
    if(type != 67 && type > 63) { 
      hideElement(document.getElementById('beamsDropDown'), true)
      hideElement(document.getElementById('postsDropDown'), true)    
      hideElement(document.getElementById('wallsDropDown'), false)
      hideElement(document.getElementById('rollerDoorsDropDown'), false)
    } else {
      hideElement(document.getElementById('beamsDropDown'), false)
      hideElement(document.getElementById('postsDropDown'), false)    
      hideElement(document.getElementById('wallsDropDown'), true)
      hideElement(document.getElementById('rollerDoorsDropDown'), true)
    }
  }
}

function selectGUISubmenu(type) {
  if(type == 32) {
      hideElement(gui_module.setup_gui.domElement, true);
      hideElement(gui_module.choose_size_gui.domElement, true);
      hideElement(gui_module.pitch_choose_size_gui.domElement, true);
      hideElement(gui_module.angled_choose_size_gui.domElement, true);
      hideElement(gui_module.building_gui.domElement, false); 
      hideElement(gui_module.shed_gui.domElement, true);  
      hideElement(gui_module.landing_choose_size_gui.domElement, true)             
  } else if(type < 7 || type == 19 || type == 23) {
      hideElement(gui_module.setup_gui.domElement, false);
      hideElement(gui_module.choose_size_gui.domElement, false);
      hideElement(gui_module.pitch_choose_size_gui.domElement, true);
      hideElement(gui_module.angled_choose_size_gui.domElement, true);
      hideElement(gui_module.building_gui.domElement, true);   
      hideElement(gui_module.shed_gui.domElement, true);   
      hideElement(gui_module.landing_choose_size_gui.domElement, true)
  } else if(type > 23 && type < 28) {
      hideElement(gui_module.setup_gui.domElement, false);
      hideElement(gui_module.choose_size_gui.domElement, true);
      hideElement(gui_module.pitch_choose_size_gui.domElement, true);
      hideElement(gui_module.angled_choose_size_gui.domElement, false);
      hideElement(gui_module.building_gui.domElement, true);   
      hideElement(gui_module.shed_gui.domElement, true);  
      hideElement(gui_module.landing_choose_size_gui.domElement, true) 
  } else if(type == 29) {
      hideElement(gui_module.setup_gui.domElement, false);
      hideElement(gui_module.choose_size_gui.domElement, true);
      hideElement(gui_module.pitch_choose_size_gui.domElement, true);
      hideElement(gui_module.angled_choose_size_gui.domElement, true);
      hideElement(gui_module.building_gui.domElement, true);   
      hideElement(gui_module.shed_gui.domElement, true);  
      hideElement(gui_module.landing_choose_size_gui.domElement, false) 
  } else if(type < 32) {
      hideElement(gui_module.setup_gui.domElement, false);
      hideElement(gui_module.choose_size_gui.domElement, true);
      hideElement(gui_module.pitch_choose_size_gui.domElement, false);
      hideElement(gui_module.angled_choose_size_gui.domElement, true);
      hideElement(gui_module.building_gui.domElement, true);   
      hideElement(gui_module.shed_gui.domElement, true);   
      hideElement(gui_module.landing_choose_size_gui.domElement, true)
  } else if(type > 63) {
      hideElement(gui_module.setup_gui.domElement, true);
      hideElement(gui_module.choose_size_gui.domElement, true);
      hideElement(gui_module.pitch_choose_size_gui.domElement, true);
      hideElement(gui_module.angled_choose_size_gui.domElement, true);
      hideElement(gui_module.building_gui.domElement, true);  
      hideElement(gui_module.shed_gui.domElement, false);    
      hideElement(gui_module.landing_choose_size_gui.domElement, true)
  }
}

function selectShedGUISubmenu(shed, shed_module) {
  if(shed.type != 67) hideElement(shed_module.front_post_offset_folder_gui.domElement, true)
  else hideElement(shed_module.front_post_offset_folder_gui.domElement, false)
  if(shed.type != 100) hideElement(shed_module.building_folder_gui.domElement, true)
  else hideElement(shed_module.building_folder_gui.domElement, false)
}

function setPriceDialog(flag) {
  hideElement(document.getElementById('priceDialog'), flag)
}

function hideElement(element, flag) {
  //$(element)?.attr("hidden", flag)
}

function act2TokenNotFound() {
  //if(findNoReactFlag()) window.location.replace('/');
}

function findNoReactFlag() {
    return no_react
}

function setupFont(text_scene) {
  var loader = new FontLoader();
  if(loader) loader.load( 'fonts/helvetiker_bold.typeface.json', function (font) {           
      gui_module.title_font = font
      text_scene()
      }, function ( xhr ) {
        console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
      }, function (err) {
      console.log( 'An error happened' );
  });
}

function setupTextGeometry(text, _size) {
  var textGeo
  if(!findNoReactFlag()) textGeo = new TextGeometry(text, {
      font: gui_module.title_font,
      size: _size,
      depth: _size / 6,
      height: _size / 6,
      bevelEnabled: false
  
  })
  return textGeo
}

export { THREE, OBJLoader, GUI, FBXLoader, OrbitControls, RGBELoader, 
  getAssetsAddress, colorSetType, selectGUISubmenu, selectShedGUISubmenu,
  act2TokenNotFound, findNoReactFlag, setupTextGeometry,
  setPriceDialog, hideElement, setupFont,
  addObj, addGLTF, loadImage, loadMaterial, getMaterial, keepObjectInsideScene, 
  getObjectsKeys, getNewAccessoryObjectHolder, updatePrice, generatePriceTable };
