import React, { Component } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import JSZipUtils from 'jszip-utils';
//npm install pizzip docxtemplater file-saver jszip-utils

// npm install html2canvas jspdf
import { product_manager_module } from '../smartkits/product_manager_module';
import { project_specification } from '../smartkits/gui_smartkits'
import Smart2DProjectionPage from './Smart2DProjectionPage';
import { findModelTypeStringGlobal } from './LysaghtHelper';
import { selectColorbond } from '../smartkits/color';


class PdfConverter extends Component {
  constructor(props) {
    super(props);
    this.state =  {
        count: 0, 
        products: product_manager_module.products,
        proPageNo: 6,
        epiPageNo: 10,
        imagePath: 'engineering/0025.jpg',
        customerPageRef: React.createRef(),
        drawingPageNo: 4,
        pdfHightRes: 1754, //877, //3508,
        pdfWidthRes: 1240, //620 //2480,
        fname: '',
        lname: '',
        email: ''
    } 
    this.pdfPages = []
    for(var i = 0; i < this.state.drawingPageNo * product_manager_module.products.length; ++i) {
        this.pdfPages.push(React.createRef())
    }
    this.pdf = new jsPDF('p', 'mm', 'a4')
    this.pdf.output('datauristring', { compress: true });

    var session = JSON.parse(window.sessionStorage.getItem('smartkits_token'))
    console.log('Session', session)
    this.state.fname = session?.fname == null ? '' : session.fname;
    this.state.lname = session?.lname == null ? '' : session.lname;
    this.state.email = session?.email == null ? '' : session.email;
  }

  downloadImage = () => {
    this.Smart2DProjectionPage.downloadImage()
  }

  updatePDFCounter = () => {
    this.setState((prevState) => ({
      count: prevState.count + 1,
    }), () => {
      this.convertToPdf();
    })
  }

  loadImage = (add) => {
    const img = new Image()
    img.src = add
    img.onload = () => {
      this.pdf.addImage(img, 'JPEG', 0, 0, 210, 270, '', 'FAST')  
      this.updatePDFCounter()
    };
  }

  initPdf = () => {
    var length = this.state.drawingPageNo * product_manager_module.products.length
    if(length == 0) return 0
    var no= this.state.count
    if(no >= length + this.state.proPageNo + this.state.epiPageNo) {
        this.pdf.save(project_specification.family_name + '_' + project_specification.first_name + '_quote_1.pdf')
        return 0;
    } else if(no < this.state.proPageNo) {      
      
      switch(no) {
        case 0:
          this.loadImage('engineering/00' + (no + 17) + '.jpg') 
          break
        case 1:
          this.pdf.addPage()
          var canvas = this.state.customerPageRef.current
          var imgData = canvas.toDataURL('image/png')
          this.pdf.addImage(imgData, 'JPEG', 0, 0, 210, 270, '', 'FAST')   
          this.updatePDFCounter()
          break
        default:
          this.loadImage('engineering/00' + (no + 17) + '.jpg') 
          this.pdf.addPage() 
          break
      }
      return 0     
    } else if(no < length + this.state.proPageNo){
      return 1       
    } else {
      var no1 = no - length - this.state.proPageNo + 1
      var s = 'engineering/00'
      if(no1 < 10) s =  s + '0'
      this.loadImage(s + no1 + '.jpg') 
      this.pdf.addPage()
      return 0    
    }  
  }

  convertToPdf = () => {
    if(this.initPdf() == 0) return
    var no = this.state.count - this.state.proPageNo
    var i = Math.floor(no / this.state.drawingPageNo)
    if(this.state.products[i].type < 15 || this.state.products[i].type == 20 || this.state.products[i].type == 21 || this.state.products[i].type == 28 || this.state.products[i].type == 67) {
      switch(no % 4) {
        case 0:
        case 1:
          this.pdf.addPage()
          break
        default:
          this.updatePDFCounter()
          return
      }      
    } else if(this.state.products[i].type > 63 && this.state.products[i].type < 100) {
      this.pdf.addPage()
    } else {
      this.updatePDFCounter()
      return
    }
    const input = this.pdfPages[no].current 
    html2canvas(input).then((canvas) => {
        var imgData = canvas.toDataURL('image/png')
        this.pdf.addImage(imgData, 'JPEG', 0, 0, 210, 270, '', 'FAST')   
        this.updatePDFCounter()
    })
  }

  makeCustomerPage = () => {
    const canvas = this.state.customerPageRef.current
    const context = canvas.getContext('2d', { willReadFrequently: true })
    const image = new Image();

    image.src = this.state.imagePath

    image.onload = () => {
      context.drawImage(image, 0, 0, canvas.width, canvas.height)
      context.fillStyle = 'black'
      context.font = '39px Arial'
      //context.fillText('Name' , 205, 1105)
      //context.fillText('ALik' , 205, 1190)
      //context.fillText('Name' , 205, 1275)
      //context.fillText('ALik' , 235, 1355)
      context.fillText(project_specification.first_name + ' ' + project_specification.family_name , 205, 1105) //, 410, 2210)
      context.fillText(project_specification.email , 205, 1190) //410, 2380)
      context.fillText(project_specification.phone_no , 205, 1275) //410, 2550)
      context.fillText(project_specification.build_street_no , 235, 1355) // 470, 2710)
      context.fillText(project_specification.build_street , 235, 1388) // 470, 2710)
      context.fillText(project_specification.build_suburb + ' ' +  project_specification.build_post_code , 235, 1435) //470, 2870)
      context.fillText(project_specification.build_state , 235, 1520) //470, 3040)   
    };
  }

  drawCanvas2Context = (i, canvas1, canvas2Ref) => {
    if(canvas1 == null || canvas2Ref == null || canvas2Ref.current == null) return
    const ctx1 = canvas1.getContext('2d', { willReadFrequently: true })
    ctx1.fillStyle = 'white'
    ctx1.fillRect(0, 0, canvas1.width, canvas1.height);
    ctx1.fillStyle = 'black'
    if(canvas2Ref) {
      const canvas2 = canvas2Ref.current
      const image = new Image();
      image.src = canvas2.toDataURL('image/png');
      var sx = canvas1.width / (canvas2.width * 1)
      var sy = canvas1.height / (canvas2.height * 1)
      const s = sx < sy ? sx: sy
      image.onload = () => {
          ctx1.drawImage(image, (canvas1.width - canvas2.width * s) / 2, (canvas1.height - canvas2.height * s) / 2, canvas2.width * s, canvas2.height * s);
      }
    }
  }

   // Method to load the template file
  loadFile(url, callback) {
      JSZipUtils.getBinaryContent(url, callback);
  }

  // Method to generate the document
  makeQuote = (v) => {
    let file = "engineering/Quotation-Smart.docx"
    switch(v) {
      case 0:        
        break;
      case 1:
        file = "engineering/Quotation-Smart.docx"
        break;
      case 2:
        file = "engineering/Quotation-Smart-1.docx"
        break;
      case 3:
        file = "engineering/Quotation-QHI.docx"
        break;
      case 4:
        file = "engineering/Quotation-QHI-1.docx"
        break;
    }
      this.loadFile(file, (error, content) => {
          if (error) {
              throw error;
          }

 

          const zip = new PizZip(content);
          const doc = new Docxtemplater(zip, {
              paragraphLoop: true,
              linebreaks: true
          });

          let name = project_specification.first_name == null ? '' : project_specification.first_name;
          let family = project_specification.family_name == null ? '' : project_specification.family_name;
          let address =  project_specification.build_street_no + ' ' + project_specification.build_street + ' ' + 
                           project_specification.build_suburb + ' ' + project_specification.build_post_code + ' ' + 
                           project_specification.build_state;
          if(address == null) address = '';
          let phone = project_specification.phone_no == null ? '' : project_specification.phone_no;
          
          let product1_detail = '';
          let product2_detail = ''; 
          let product3_detail = '';
          let product4_detail = '';
          let product5_detail = '';
          let product6_detail = '';
          let product1_price = '';
          let product2_price = '';
          let product3_price = '';
          let product4_price = '';
          let product5_price = '';
          let product6_price = '';
          let qty1 = '';
          let qty2 = '';
          let qty3 = '';
          let qty4 = '';
          let qty5 = '';
          let qty6 = '';
          let total = 0;
          let subtotal = 0;
          let delivery = 0;
          let jobNumber = '';

          let price = 0;
          var products = product_manager_module.products
          for(var i = 0, j = 0; i < products.length; ++i) {
            if(products[i].type < 13) {
              switch(j) {
                case 0:
                  product1_detail = findModelTypeStringGlobal(products[i].price_index, 1);
                  product1_price = products[i].eng_price_total_Ex_GST;
                  qty1 = 1;
                  if(product1_price == null || product1_price == '') {
                    product1_price = '';
                    subtotal = '';
                  } else {   
                    price = parseInt(product1_price, 0)  ;
                    subtotal = price;           
                    product1_price = 'AU$' + price
                    delivery =  parseInt(products[i].eng_price_packing_delivery);
                    jobNumber =  products[i].eng_job_number;
                  }
                  break;
                case 1:
                  product2_detail = findModelTypeStringGlobal(products[i].price_index, 1);
                  product2_price = products[i].eng_price_total_Ex_GST;
                  qty2 = 1;
                  if(product2_price == null || product2_price == '') {
                    product2_price = '';
                    subtotal = '';
                  } else {     
                    price = parseInt(product2_price, 0)              
                    if(subtotal != '') {
                      subtotal = subtotal + price;
                    }
                    product2_price = 'AU$' + price
                  }
                  break;
                case 2:
                  product3_detail = findModelTypeStringGlobal(products[i].price_index, 1);
                  product3_price = products[i].eng_price_total_Ex_GST;
                  qty3 = 1;
                  if(product3_price == null || product3_price == '') {
                    product3_price = '';
                    subtotal = '';
                  } else {                  
                    price = parseInt(product3_price, 0)              
                    if(subtotal != '') {
                      subtotal = subtotal + price;
                    }
                    product3_price = 'AU$' + price
                  }
                  break;
                case 3:
                  product4_detail = findModelTypeStringGlobal(products[i].price_index, 1);
                  product4_price = products[i].eng_price_total_Ex_GST;
                  qty4 = 1;
                  if(product4_price == null || product4_price == '') {
                    product4_price = '';
                    subtotal = '';
                  } else {                  
                    price = parseInt(product4_price, 0)              
                    if(subtotal != '') {
                      subtotal = subtotal + price;
                    }
                    product4_price = 'AU$' + price
                  }
                  break;
                case 4:
                  product5_detail = findModelTypeStringGlobal(products[i].price_index, 1);
                  product5_price = products[i].eng_price_total_Ex_GST;
                  qty5 = 1;
                  if(product5_price == null || product5_price == '') {
                    product5_price = '';
                    subtotal = '';
                  } else {                  
                    price = parseInt(product5_price, 0)              
                    if(subtotal != '') {
                      subtotal = subtotal + price;
                    }
                    product5_price = 'AU$' + price
                  }
                  break;
                case 5:
                  product6_detail = findModelTypeStringGlobal(products[i].price_index, 1);
                  product6_price = products[i].eng_price_total_Ex_GST;
                  qty6 = 1;
                  if(product6_price == null || product6_price == '') {
                    product6_price = '';
                    subtotal = '';
                  } else {                  
                    price = parseInt(product6_price, 0)              
                    if(subtotal != '') {
                      subtotal = subtotal + price;
                    }
                    product6_price = 'AU$' + price
                  }
                  break;
              }
              j = j + 1;
            }            
          }
          if(subtotal != '') {
            total = 'AU$' + parseInt((subtotal + delivery) * 1.1, 0);
            subtotal = 'AU$' + subtotal;
            delivery = 'AU$' + delivery;
          } else {
            total = '';
            delivery = '';
          }
          let date = new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short', 
            day: 'numeric'
          });
          let quoterName = this.state.fname + ' ' + this.state.lname;
          let quoterEmail = this.state.email;
          // Set the dynamic data
          doc.setData({
              name: name,
              family: family,
              address: address,
              phone: phone,
              product1_detail:  product1_detail,
              product2_detail:  product2_detail,
              product3_detail:  product3_detail,
              product4_detail:  product4_detail,
              product5_detail:  product5_detail,
              product6_detail:  product6_detail,

              product1_price:  product1_price,
              product2_price:  product2_price,
              product3_price:  product3_price,
              product4_price:  product4_price,
              product5_price:  product5_price,
              product6_price:  product6_price,

              qty1: qty1,
              qty2: qty2,
              qty3: qty3,
              qty4: qty4,
              qty5: qty5,
              qty6: qty6,

              subtotal: subtotal,
              delivery: delivery,
              total: total,
              jobNumber: jobNumber,
              date: date,
              quoterName: quoterName,
              quoterEmail: quoterEmail,

              image1: this.test,
              image2: "image2",
            })
          try {
              doc.render();
          } catch (error) {
              console.error('Error rendering the document:', error);
              throw error;
          }

          const blob = doc.getZip().generate({ type: 'blob' });

          saveAs(blob, name +'-' + family + '.docx');
      });
  };

  


  fetchImageAsBase64 = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  componentDidMount() {
    this.makeCustomerPage()

    for(var i = 0; i < this.pdfPages.length; ++i) {
        var j = Math.floor(i / this.state.drawingPageNo)
        var product = this.state.products[j]
        const canvas1 = this.pdfPages[i].current
        switch(i % 4) {
          case 0:
            var canvas2Ref = product.canvasRef
            var scaledCanvas2 = product.scaledCanvasRef.current
            this.drawCanvas2Context(j, canvas1, canvas2Ref)
            this.drawCanvas2Context(j, scaledCanvas2, canvas2Ref)
            break
          case 1:
            if(product.type < 32 || product.type == 67) {
              var canvas3Ref = product.postLocationCanvasRef
              var scaledPostLocationCanvas = product.scaledPostLocationCanvasRef.current
              this.drawCanvas2Context(j, canvas1, canvas3Ref)
              this.drawCanvas2Context(j, scaledPostLocationCanvas, canvas3Ref)
            } else if(product.type > 63)  {
              var canvas3Ref = product.canvasRef1
              var scaledPostLocationCanvas = product.scaledCanvasRef1.current
              this.drawCanvas2Context(j, canvas1, canvas3Ref)
              this.drawCanvas2Context(j, scaledPostLocationCanvas, canvas3Ref)
            }
            break
          case 2:
            var canvas2Ref = product.canvasRef2
            var scaledCanvas2 = product.scaledCanvasRef2.current
            this.drawCanvas2Context(j, canvas1, canvas2Ref)
            this.drawCanvas2Context(j, scaledCanvas2, canvas2Ref)
            break
          case 3:
            var canvas2Ref = product.canvasRef3
            var scaledCanvas2 = product.scaledCanvasRef3.current
            this.drawCanvas2Context(j, canvas1, canvas2Ref)
            this.drawCanvas2Context(j, scaledCanvas2, canvas2Ref)
            break
        }
    }
  }

  render() {
    const canvasStyle = {
        width: '1px',  
        height: '1px',  
        overflow: 'auto',
        border: '1px solid #000',
        
    };
    return (
      <div>
       <Smart2DProjectionPage ref={ref => this.Smart2DProjectionPage = ref} />
       <div style={canvasStyle}>
        <canvas ref={this.state.customerPageRef} src={this.state.imagePath} alt="Description of the image" width={this.state.pdfWidthRes} height={this.state.pdfHightRes} />
       </div>
       {this.state.products.map(({length, projection, height, angle, front_posts_no, brackets_no, canvasRef}, index) => 
       (<div style={{     
          margin: '5px',
        }}>
        <div style={canvasStyle}>
         <canvas ref={this.pdfPages[this.state.drawingPageNo * index]} width={this.state.pdfWidthRes} height={this.state.pdfHightRes} />
       </div>
       <div style={canvasStyle}>
         <canvas ref={this.pdfPages[this.state.drawingPageNo * index + 1]} width={this.state.pdfWidthRes} height={this.state.pdfHightRes} />
       </div>
       <div style={canvasStyle}>
         <canvas ref={this.pdfPages[this.state.drawingPageNo * index + 2]} width={this.state.pdfWidthRes} height={this.state.pdfHightRes} />
       </div>
       <div style={canvasStyle}>
         <canvas ref={this.pdfPages[this.state.drawingPageNo * index + 3]} width={this.state.pdfWidthRes} height={this.state.pdfHightRes} />
       </div>
       </div>))} 
      </div>     
    );
  }
}

export default PdfConverter;