import React from 'react';
import { sceneItems } from '../smartkits/gui_smartkits';
import { config } from '../smartkits/initSmartkits';

const options = [
  { value: 'sunrise', label: 'Sunrise' },
  { value: 'morning', label: 'Morning' },
  { value: 'midday', label: 'Midday' },
  { value: 'afternoon', label: 'Afternoon' },
  { value: 'sunset', label: 'Sunset' },
  { value: 'night', label: 'Night' },
];

class SmartSUnLightSubmenu extends React.Component {
  constructor() {
    super();
    this.state =  { 
      sun: 'midday',
      intensity: 1.5,
      travel: 0,
      ambient: config.home ? 1.8 : 0,
      intensity2: 0.8,
      x2: 0,
      y2: -3,
      z2: 6
    }   
  }

  updateLight = () => {
    var r = 150
    var x = -1 * r * Math.sin(this.state.travel * Math.PI / 180);
    var y = r * Math.cos(this.state.travel * Math.PI / 180);
    sceneItems.light.position.set(x, y, -3)
  }

  setIntensity = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
        intensity: v
    });
    sceneItems.light.intensity = v
  }

  setTravel = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
        travel: v
    });
    this.updateLight()
  }

  setAmbient = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
        ambient: v
    });
    sceneItems.ambient.intensity = v
  }

  setSunPosition = ({ target }) => {
    var v = target.value
    this.setState({
        sun: v
    });
    
    var t = 0 
    var i = 0.8
    var a = 0.1
    if(v == "sunrise") {
        t = -80;
    } else if(v == "morning") {
        t = -70;
        i = 1.0;
    } else if(v == "midday") {
        t= 0;
        i = 1.5;
        a = 0.1
    } else if(v == "afternoon") {
        t = 70;
        i = 1.0;
    } else if(v == "sunset") {
        t = 80;
    } else {
        t = 0
        i = 0
        a = 0.1
    }

    this.state.travel = t
    this.state.ambient = a
    this.state.intensity = i
    this.updateLight()
    sceneItems.light.intensity = this.state.intensity
    sceneItems.ambient.intensity = this.state.ambient
  }

  setIntensity2 = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
        intensity2: v
    });
    sceneItems.light_2nd.intensity = v
  }

  setX2 = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
        x2: v
    });
    sceneItems.light_2nd.position.set(v, this.state.y2, this.state.z2)
  }

  setY2 = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
        y2: v
    });
    sceneItems.light_2nd.position.set(this.state.x2, v, this.state.z2)
  }

  setZ2 = (event) => {
    var v = event.target.valueAsNumber
    this.setState({
        z2: v
    });
    sceneItems.light_2nd.position.set(this.state.x2, this.state.y2, v)
  }

  render() {
    return (
      <div>
        <div id='sun_light'>
          <select  className="btn bg-info col-md-12 mx-1 my-1" 
            value={this.state.sun} 
            onChange={this.setSunPosition}
          >
          {options.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
        </select>
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Intensity</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min="0" 
            max="3" 
            step="0.1" 
            value={this.state.intensity}
            onChange={this.setIntensity}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min="0" 
            max="3" 
            step="0.1" 
            value={this.state.intensity}
            onChange={this.setIntensity}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Sun Travel</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min="-90" 
            max="90" 
            step="1" 
            value={this.state.travel}
            onChange={this.setTravel}
        />
        <input type='range' className="col-md-5 mx-1 my-1"
            min="-90" 
            max="90" 
            step="1" 
            value={this.state.travel}
            onChange={this.setTravel}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Ambient</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min="0"
            max="10" 
            step="0.1" 
            value={this.state.ambient}
            onChange={this.setAmbient}
        />
        <input type='range' className="col-md-5 mx-1 my-1"
            min="0"
            max="10" 
            step="0.1" 
            value={this.state.ambient}
            onChange={this.setAmbient}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Intensity 2nd Source</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min="0" 
            max="3" 
            step="0.1" 
            value={this.state.intensity2}
            onChange={this.setIntensity2}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min="0" 
            max="3" 
            step="0.1" 
            value={this.state.intensity2}
            onChange={this.setIntensity2}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">X 2nd Source </button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min="-40" 
            max="40" 
            step="1" 
            value={this.state.x2}
            onChange={this.setX2}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min="-40" 
            max="40" 
            step="1" 
            value={this.state.x2}
            onChange={this.setX2}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Y 2nd Source</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min="-40" 
            max="40" 
            step="1" 
            value={this.state.y2}
            onChange={this.setY2}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min="-40" 
            max="40" 
            step="1" 
            value={this.state.y2}
            onChange={this.setY2}
        />
        <button className="btn btn-secondary col-md-12 mx-1 my-1">Z 2nd Source</button>
        <input type='number' className="col-md-5 mx-1 my-1" 
            min="-40" 
            max="40" 
            step="1" 
            value={this.state.z2}
            onChange={this.setZ2}
        />
        <input type='range' className="col-md-5 mx-1 my-1" 
            min="-40" 
            max="40" 
            step="1" 
            value={this.state.z2}
            onChange={this.setZ2}
        />
        </div>
      </div>
     );  
  }
}

export default SmartSUnLightSubmenu;
