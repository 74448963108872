import React from 'react';
import { gui_module } from '../smartkits/gui_smartkits';
import { product_manager_module } from '../smartkits/product_manager_module';

class SmartQuoteSubmenu extends React.Component {
  constructor(props) {
    super(props);
  }

  makePDF = () => {
    this.props.makePDF()
  }

  downloadImage = () => {
    this.props.downloadImage()
  }

  makeQuote = (v) => {
    this.props.makeQuote(v)
  }

  informSelect = () => {
  }

  componentDidMount() {
    this.informSelect()
  }

  render() {
    return (
      <div>
       <div id='quote_submenu_setup'>
        <button className="btn btn-primary col-md-12 mx-1 my-1" onClick={this.downloadImage}>Download Image</button>
        <button className="btn btn-primary col-md-12 mx-1 my-1" onClick={this.makePDF}>Make PDF</button>
        <button className="btn btn-info col-md-12 mx-1 my-1" onClick={() => {this.makeQuote(1)}}>Smart Quote QR</button>
        <button className="btn btn-info col-md-12 mx-1 my-1" onClick={() => {this.makeQuote(2)}}>Smart Quote</button>
        <button className="btn btn-warning col-md-12 mx-1 my-1" onClick={() => {this.makeQuote(3)}}>QHI Quote QR</button>
        <button className="btn btn-warning col-md-12 mx-1 my-1" onClick={() => {this.makeQuote(4)}}>QHI Quote</button>
        </div>
      </div>
     );  
  }
}

export default SmartQuoteSubmenu;
