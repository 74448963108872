import React from 'react';
import SmartCreateSubmenu from './SmartCreateSubmenu';
import SmartSizeSubmenu from './SmartSizeSubmenu';
import SmartSunLightSubmenu from './SmartSunLightSubmenu';
import SmartOutputSubmenu from './SmartOutputSubmenu';
import Smart3DSubmenu from './Smart3DSubmenu';
import SmartColourSubmenu from './SmartColourSubmenu'
import SmartBasicSetupSubmenu from './SmartBasicSetupSubmenu';
import SmartAdvancedSetupSubmenu from './SmartAdvancedSetupSubmenu';
import SmartAccessorySubmenu from './SmartAccessorySubmenu';
import SmartWingSubmenu from './SmartWingSubmenu';
import SmartLandscapingSetupubmenu from './SmartLandscapingSetupSubmenu';
import SmartPostSetupSubmenu from './SmartPostSetupSubmenu';
import SmartSceneSetupubmenu from './SmartSceneSetupSubmenu';
import SmartPriceSubtoolbar from './SmartPriceSubtoolbar';
import Smart2DProjectionSubmenu from './Smart2DProjectionSubmenu';
import SmartQuoteSubmenu from './SmartQuoteSubmenu';


class SmartMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state =  { 
    }   
  }

  informSelect = () => {
    this.smartSizeSubmenuRef.informSelect()
    this.smartCreateSubmenuRef.updateIndex()
    this.smartBasicSetupSubmenuRef.informSelect()
    this.smartAdvancedSetupSubmenuRef.informSelect()
    this.smart3DSetupSubmenuRef.informSelect()
    this.smartColourSetupSubmenuRef.informSelect()
    this.smartAccessorySetupSubmenuRef.informSelect()
    this.smartWingSubmenuRef.informSelect()
    this.smartLandscapingSetupSubmenuRef.informSelect()
    this.smartPostSetupSubmenuRef.informSelect()
    this.smartSceneSetupSubmenuRef.informSelect()
    this.Smart2DProjectionSubmenu.informSelect()
    this.SmartQuoteSubmenu.informSelect()
  }

  generatePriceTable = () => {
    this.props.generatePriceTable()
  }

  setPriceSpace = (v) => {
    this.props.setPriceSpace(v)
  }

  setIndex = (v) => {
    this.props.setIndex(v)
  }

  runTask = (v) => {
    this.props.runTask(1)
  }

  makePDF = () => {
    this.props.makePDF()
  }

  makeQuote = (v) => {
    this.props.makeQuote(v)
  }

  
  downloadImage = () => {
    this.props.downloadImage()
  }

  componentDidMount() {
  }

  render() {
    return (
      <div>
        <SmartCreateSubmenu  ref={ref => this.smartCreateSubmenuRef = ref} />
        <SmartSizeSubmenu generatePriceTable={this.generatePriceTable} ref={ref => this.smartSizeSubmenuRef = ref} />
        <SmartBasicSetupSubmenu ref={ref => this.smartBasicSetupSubmenuRef = ref}/>
        <SmartAdvancedSetupSubmenu ref={ref => this.smartAdvancedSetupSubmenuRef = ref}/>
        <SmartSunLightSubmenu />
        <SmartOutputSubmenu />
        <Smart3DSubmenu ref={ref => this.smart3DSetupSubmenuRef = ref} />
        <SmartColourSubmenu ref={ref => this.smartColourSetupSubmenuRef = ref} />
        <SmartAccessorySubmenu ref={ref => this.smartAccessorySetupSubmenuRef = ref} />
        <SmartWingSubmenu ref={ref => this.smartWingSubmenuRef = ref} />
        <SmartLandscapingSetupubmenu ref={ref => this.smartLandscapingSetupSubmenuRef = ref} />
        <SmartPostSetupSubmenu ref={ref => this.smartPostSetupSubmenuRef = ref}/>
        <SmartSceneSetupubmenu ref={ref => this.smartSceneSetupSubmenuRef = ref} />
        <SmartPriceSubtoolbar  setPriceSpace={this.setPriceSpace} />
        <Smart2DProjectionSubmenu runTask={this.runTask} setIndex={this.setIndex} ref={ref => this.Smart2DProjectionSubmenu = ref}  />
        <SmartQuoteSubmenu makePDF={this.makePDF} downloadImage={this.downloadImage} makeQuote={this.makeQuote} ref={ref => this.SmartQuoteSubmenu = ref}  />
      </div>
     );  
  }
}

export default SmartMenu;
